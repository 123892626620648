import { Button, ButtonGroup, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from "sweetalert2";
import Table from "../../Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import apiFunctions from "../../../apiKit/api";
import { appConstants } from "../../../apiKit/appConstant";

const TestomonialSection = () => {
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({
        description: {
            title: '',
            description: ''
        },
        status: 'active',
    });
    const [filteredRows, setFilteredRows] = useState([]);
    const [staffs, setStaffs] = useState([]);
    const [sponsers, setSponsers] = useState([]);
    const [Image, setImage] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [Title, setTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [staffTitle, setStaffTitle] = useState("");
    const [testTitle, setTestTitle] = useState("");
    const [testLink, setTestLink] = useState("");
    const [sponserTitle, setSponserTitle] = useState("");
    const { staffdata, sponserdata, testdata } = useSelector((state) => state.api);
    const [type, setType] = useState("");
    const dispatch = useDispatch();
    const setData = (data) => {
        console.log(data[0].content, "data");
        if (data && data[0].content) {
            try {
                const parse = JSON.parse(data[0].content);
                setTitle(parse?.title);
                setSubTitle(parse?.orangetxt);
                setSponserTitle(parse?.sponstitle || 'Default Sponsor Title');
                setStaffTitle(parse?.stafftitle || 'Default Staff Title');
                setTestTitle(parse?.testtitle || 'Default Title');
                setTestLink(parse?.testlink || 'Default');

            } catch (error) {
                console.error('Failed to parse JSON:', error);
            }
        } else {
            console.error('Data content is undefined or empty');
        }
    };


    useEffect(() => {
        const parsedData = staffdata?.images?.map(item => {
            try {
                const parsedDescription = typeof item.description === 'string' ? JSON.parse(item.description) : item.description;
                return {
                    ...item,
                    description: parsedDescription
                };
            } catch (error) {
                console.error("Failed to parse description:", error);
                return item;
            }
        }) || [];
        setStaffs(parsedData);
        const sponsData = sponserdata?.images?.map(item => {
            try {
                const parsedDescription = typeof item.description === 'string' ? JSON.parse(item.description) : item.description;
                return {
                    ...item,
                    description: parsedDescription
                };
            } catch (error) {
                console.error("Failed to parse description:", error);
                return item;
            }
        }) || [];
        setSponsers(sponsData);
        setData(testdata)
    }, [staffdata, sponserdata, testdata]);
    // Columns for the staffs
    const columnsStaffs = [
        {
            field: 'imageUrl',
            headerName: 'Image',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                params.row && (
                    <img
                        src={appConstants.imageUrl + params.row.imageUrl}
                        alt={params.row.description?.title || 'Image'}
                        width={100}
                    />
                ) // Safely render the image if params.row exists
        },
        {
            field: 'description.title',
            headerName: 'Title',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.title : '-'
        },
        {
            field: 'description.description',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.description : '-'
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                params.row && (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row, "Staff")}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row, "Staff")}><VisibilityIcon /></IconButton>
                        <IconButton onClick={() => handleRemove(params.row, "Staff")}><DeleteIcon /></IconButton>
                    </ButtonGroup>
                )
            ) // Safely render the action buttons if params.row exists
        },
    ];
    // Columns for the sponsers
    const columnsSponsers = [
        {
            field: 'imageUrl',
            headerName: 'Image',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                params.row && (
                    <img
                        src={appConstants.imageUrl + params.row.imageUrl}
                        alt={params.row.description?.title || 'Image'}
                        width={100}
                    />
                ) // Safely render the image if params.row exists
        },
        {
            field: 'description.title',
            headerName: 'Title',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.title : '-'
        },
        {
            field: 'description.description',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.description : '-'
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                params.row && (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row, "Sponser")}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row, "Sponser")}><VisibilityIcon /></IconButton>
                        <IconButton onClick={() => handleRemove(params.row, "Sponser")}><DeleteIcon /></IconButton>
                    </ButtonGroup>
                )
            ) // Safely render the action buttons if params.row exists
        },
    ];

    // Function to handle Delete
    const handleRemove = (row, mode) => {
        setType(mode);
        Swal.fire({
            text: `Are you sure you want to delete?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                if (mode === "Staff") {
                    apiFunctions.deleteCmsHomeStaffImage([row.id]).then((res) => {
                        if (res.status === 200) {
                            Swal.fire({
                                text: res.message,
                                icon: 'success',
                            });
                            dispatch(fetchAPIs());
                        } else {
                            Swal.fire({
                                text: res.message,
                                icon: 'error',
                            });
                        }
                    }).catch(() => {
                        Swal.fire({
                            text: 'An error occurred. Please try again later.',
                            icon: 'error',
                        });
                    });
                } else {
                    apiFunctions.deleteCmsHomeSponserImage([row.id]).then((res) => {
                        if (res.status === 200) {
                            Swal.fire({
                                text: res.message,
                                icon: 'success',
                            });
                            dispatch(fetchAPIs());
                        } else {
                            Swal.fire({
                                text: res.message,
                                icon: 'error',
                            });
                        }
                    }).catch(() => {
                        Swal.fire({
                            text: 'An error occurred. Please try again later.',
                            icon: 'error',
                        });
                    });
                }

            }
        });
    };

    const handleViewOpen = (row, type) => {
        setType(type);
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleEditOpen = (row, mode) => {
        setType(mode);
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleEditSave = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", selectedRow?.status);
        form.append("id", selectedRow?.id);
        form.append("description", JSON.stringify(json))
        if (type === "Staff") {
            apiFunctions.updateCmsHomeStaffImage(form).then((res) => {
                if (res.status === 200) {
                    handleEditClose()
                    Swal.fire({
                        text: res.message,
                        icon: 'success',
                    });
                    dispatch(fetchAPIs());
                } else {
                    handleEditClose();
                    Swal.fire({
                        text: res.message,
                        icon: 'error',
                    });
                }
            }).catch(() => {
                handleEditClose()
                Swal.fire({
                    text: 'An error occurred. Please try again later.',
                    icon: 'error',
                });
            });
        } else {
            apiFunctions.updateCmsHomeSponserImage(form).then((res) => {
                if (res.status === 200) {
                    handleEditClose()
                    Swal.fire({
                        text: res.message,
                        icon: 'success',
                    });
                    dispatch(fetchAPIs());
                } else {
                    handleEditClose();
                    Swal.fire({
                        text: res.message,
                        icon: 'error',
                    });
                }
            }).catch(() => {
                handleEditClose()
                Swal.fire({
                    text: 'An error occurred. Please try again later.',
                    icon: 'error',
                });
            });
        }


        // setIsEditOpen(false);
        // setSelectedRow(null);
        // Swal.fire('', 'Saved Successfully', 'success');
    };

    const handleAddOpen = (type) => {
        setType(type);
        setSelectedRow({
            description: {
                title: '',
                description: ''
            },
            status: 'active',
        });
        setIsAddOpen(true);
    };

    const handleAddClose = () => {
        setIsAddOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleAddSave = (e) => {
        e.preventDefault();
        setFilteredRows([...filteredRows, { ...selectedRow, id: staffs.length + 1 }]);
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", "active")
        form.append("description", JSON.stringify(json))
        if (type === "Staff") {
            apiFunctions.addCmsHomeStaffImage(form).then((res) => {
                if (res.status === 200) {
                    handleAddClose()
                    Swal.fire({
                        text: res.message,
                        icon: 'success',
                    });
                    dispatch(fetchAPIs());
                } else {
                    handleAddClose();
                    Swal.fire({
                        text: res.message,
                        icon: 'error',
                    });
                }
            }).catch(() => {
                handleAddClose()
                Swal.fire({
                    text: 'An error occurred. Please try again later.',
                    icon: 'error',
                });
            });
        } else {
            apiFunctions.addCmsHomeSponserImage(form).then((res) => {
                if (res.status === 200) {
                    handleAddClose()
                    Swal.fire({
                        text: res.message,
                        icon: 'success',
                    });
                    dispatch(fetchAPIs());
                } else {
                    handleAddClose();
                    Swal.fire({
                        text: res.message,
                        icon: 'error',
                    });
                }
            }).catch(() => {
                handleAddClose()
                Swal.fire({
                    text: 'An error occurred. Please try again later.',
                    icon: 'error',
                });
            });
        }


    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(file);
            setImage(objectUrl);
        }
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "testimonials",
            "content": {
                "title":Title,
                "orangetxt":subTitle,
                "sponstitle": sponserTitle,
                "stafftitle": staffTitle,
                "testtitle": testTitle,
                "testlink": testLink
            }
        }
        apiFunctions.updateCmsHomeTestimonialContent(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-lg-2 p-1">
                        <CardContent>
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <h5><b>Testimonial</b></h5>
                                                <input value={testTitle} onChange={(e) => setTestTitle(e.target.value)} type="text" className="form-control" placeholder="Testimonial" />
                                                <input value={testLink} onChange={(e) => setTestLink(e.target.value)} type="text" className="form-control mt-1" placeholder="Testimonial link" />
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <h5><b>Title</b></h5>
                                                <input value={Title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Connections That" />
                                                <input value={subTitle} onChange={(e) => setSubTitle(e.target.value)} type="text" className="form-control mt-1" placeholder="Transform Lives" />
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <h5><b>Our-Staff</b></h5>
                                                <label>Title</label>
                                                <input value={staffTitle} onChange={(e) => setStaffTitle(e.target.value)} type="text" className="form-control" placeholder="Our Staff" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <h5><b>Our-Sponser</b></h5>
                                                <label>Title</label>
                                                <input value={sponserTitle} onChange={(e) => setSponserTitle(e.target.value)} type="text" className="form-control" placeholder="Our Sponser" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                    </div>
                                </form>
                            </div>
                            <div className="d-flex justify mt-3">
                                <h5><b>Staffs</b></h5>
                                <Button className="btn mb-2" onClick={() => handleAddOpen("Staff")} endIcon={<AddIcon />} variant="contained">Add new</Button>
                            </div>
                            <Table rows={staffs} columns={columnsStaffs} />
                            <div className="d-flex justify mt-3">
                                <h5><b>Sponsers</b></h5>
                                <Button className="btn mb-2" onClick={() => handleAddOpen("Sponser")} endIcon={<AddIcon />} variant="contained">Add new</Button>
                            </div>
                            <Table rows={sponsers} columns={columnsSponsers} />
                        </CardContent>
                    </Card>
                </div>
            </div>
            {/* View Modal */}
            <Dialog open={isViewOpen} onClose={handleViewClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>View {type}</DialogTitle>
                <DialogContent>
                    <img src={appConstants.imageUrl + selectedRow?.imageUrl} alt={selectedRow?.description?.title} style={{ width: "350px", height: "240px", marginTop: '10px' }} />
                    <table className="table table-bordered mt-2">
                        <tbody>
                            <tr>
                                <td><p><strong>Title:</strong></p></td>
                                <td><p>{selectedRow?.description?.title}</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Description:</strong></p></td>
                                <td><p>{selectedRow?.description?.description}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleEditClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Edit {type}</DialogTitle>
                <form onSubmit={handleEditSave}>
                    <DialogContent>
                        <img
                            style={{ width: "350px", height: "240px" }}
                            className="rounded"
                            src={Image ? Image : appConstants.imageUrl + selectedRow?.imageUrl}
                            alt="demo"
                        />
                        <div className="d-flex mt-2">
                            <input
                                className="form-control foot-input"
                                onChange={handleFileChange}
                                type="file"
                                accept="image/png, image/jpeg"
                            />
                            <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                        </div>
                        <TextField
                            margin="dense"
                            label="Title"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.title || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    title: e.target.value
                                }
                            }))}
                        />
                        <TextField
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.description || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    description: e.target.value
                                }
                            }))}
                        />
                        {/* Status Toggle */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedRow?.status === 'active'}
                                    onChange={(e) => setSelectedRow((prevRow) => ({
                                        ...prevRow,
                                        status: e.target.checked ? 'active' : 'inactive'
                                    }))}
                                    color="primary"
                                />
                            }
                            label="Active"
                            labelPlacement="end"
                            style={{ marginTop: '1rem' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn" type="submit" variant="contained">Save</Button>
                        <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>


            {/* Add Modal */}
            <Dialog open={isAddOpen} onClose={handleAddClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Add New {type}</DialogTitle>
                <form onSubmit={handleAddSave}>
                    <DialogContent>
                        {Image ?
                            <img
                                style={{ width: "350px", height: "240px" }}
                                className="rounded"
                                src={Image}
                                alt="demo"
                            /> : ''}
                        <div className="d-flex mt-2">
                            <input
                                className="form-control foot-input"
                                onChange={handleFileChange}
                                type="file"
                                accept="image/png, image/jpeg"
                            />
                            <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                        </div>
                        <TextField
                            margin="dense"
                            label="Title"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.title || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    title: e.target.value
                                }
                            }))}
                        />
                        <TextField
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.description || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    description: e.target.value
                                }
                            }))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn" type="submit" variant="contained">Save</Button>
                        <Button variant="contained" className="grey-btn" onClick={handleAddClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
export default TestomonialSection;