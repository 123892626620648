import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import { CardContent } from "@mui/material";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { useLocation, Link } from "react-router-dom";
import "../custom.css";
import Logo from "../Images/logo.png";
import { pageRoutes } from "../config/route";
import { Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MemoryIcon from '@mui/icons-material/Memory';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import ListIcon from '@mui/icons-material/List';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import InfoIcon from '@mui/icons-material/Info';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import DnsIcon from '@mui/icons-material/Dns';
const SideBar = () => {
    const location = useLocation();
    const [openCms, setOpenCms] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);
    const [openInvolved, setOpenInvolved] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("cms")) {
            setOpenCms(true);
            if (location.pathname.includes("about")) {
                setOpenAbout(true);
            }
            if (location.pathname.includes("get-involve")) {
                setOpenInvolved(true);
            }
        } else {
            setOpenCms(false);
        }
    }, [location.pathname]);

    const handleCmsClick = () => {
        setOpenCms(!openCms);
    };
    const handleAboutClick = () => {
        setOpenAbout(!openAbout);
        setOpenInvolved(false);
    };
    const handleInvolveClick = () => {
        setOpenAbout(false);
        setOpenInvolved(!openInvolved);
    };
    return (
        <>
            <Card elevation={0} className="side-card scrollbar" id="custom-scroll">
                <CardContent>
                    <div className="mb-3">
                        <div className="text-center mt-lg-3 mt-5 " >
                            <img alt="Logo" className="logo" src={Logo} />
                        </div>
                    </div>
                    <div className="mt-2">
                        <Link to={pageRoutes.dashboard} className="link">
                            <div className={location.pathname.includes("dashboard") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "d-flex mt-2 mx-4 nav-link"}>
                                <AutoAwesomeMosaicIcon />
                                <h6 className="ms-2 dash_text">Dashboard</h6>
                            </div>
                        </Link>
                    </div>
                    {/* <div>
                        <Link to={pageRoutes.banners} className="link">
                            <div className={location.pathname.includes("banner") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <PermMediaIcon />
                                <h6 className="ms-2 dash_text">Banners</h6>
                            </div>
                        </Link>
                    </div> */}
                    <div>
                        <Link to={pageRoutes.FAQ} className="link">
                            <div className={location.pathname.includes("faq") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <LiveHelpOutlinedIcon />
                                <h6 className="ms-2 dash_text">FAQ</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.myworld} className="link">
                            <div className={location.pathname.includes("myworld-banner") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <PermMediaIcon />
                                <h6 className="ms-2 dash_text">World Banner</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.header} className="link">
                            <div className={location.pathname.includes("header") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <MemoryIcon />
                                <h6 className="ms-2 dash_text">Header</h6>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <Link to={pageRoutes.footer} className="link">
                            <div className={location.pathname.includes("footer") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"}>
                                <ListIcon />
                                <h6 className="ms-2 dash_text">Footer</h6>
                            </div>
                        </Link>
                    </div>
                    <div className={location.pathname.includes("cms") ? "mt-2 mx-4 d-flex nav-link active-link-text" : "mt-2 d-flex mx-4 nav-link"} onClick={handleCmsClick}>
                        <BallotOutlinedIcon />
                        <h6 className="ms-2 dash_text">CMS</h6>
                        {openCms ? <ExpandLessIcon className="ms-4" /> : <ExpandMoreIcon className="ms-4" />}
                    </div>
                    <Collapse in={openCms} className="cms-section">
                        <div className="mt-2 mx-4 ps-4">
                            <Link to={pageRoutes.cmsHome} className="link">
                                <div className={location.pathname.includes("home") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                    {/* <HomeRoundedIcon /> */}
                                    <h6 className="ms-2 dash_text">Home</h6>
                                </div>
                            </Link>
                        </div>
                        <div className="mt-2 mx-4 ps-4">
                            <Link to={pageRoutes.campaign} className="link">
                                <div className={location.pathname.includes("campaign") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                    {/* <DnsIcon /> */}
                                    <h6 className="ms-2 dash_text">Campaign banner</h6>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="mt-2 mx-4 ps-4">
                            <Link to={pageRoutes.sponserChild} className="link">
                                <div className={location.pathname.includes("sponser-child") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                    <VolunteerActivismIcon />
                                    <h6 className="ms-2 dash_text">Sponser-Child</h6>
                                </div>
                            </Link>
                        </div> */}
                        <div className="mt-2 mx-4 ps-4">
                            <div className={location.pathname.includes("about") ? "mt-2 d-flex nav-link active-link-text" : "mt-2 d-flex nav-link"} onClick={handleAboutClick}>
                                <InfoIcon />
                                <h6 className="ms-2 dash_text">About</h6>
                                {openAbout ? <ExpandLessIcon className="ms-4" /> : <ExpandMoreIcon className="ms-4" />}
                            </div>
                        </div>
                        <Collapse className="bg-white" style={{ marginLeft: "20px" }} in={openAbout}>
                            <div className="mt-2 mx-4 ps-1">
                                <Link to={pageRoutes.howtowork} className="link">
                                    <div className={location.pathname.includes("how-to-work") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                        <h6 className="ms-2 dash_text">How to Work</h6>
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-2 mx-4 ps-1">
                                <Link to={pageRoutes.storyofchange} className="link">
                                    <div className={location.pathname.includes("story-of-change") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                        <h6 className="ms-2 dash_text">Story of Change</h6>
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-2 mx-4 ps-1">
                                <Link to={pageRoutes.partnerspeak} className="link">
                                    <div className={location.pathname.includes("partner-speak") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                        <h6 className="ms-2 dash_text">Partner Speak</h6>
                                    </div>
                                </Link>
                            </div>
                            <div className="mt-2 mx-4 ps-1">
                                <Link to={pageRoutes.leadership} className="link">
                                    <div className={location.pathname.includes("leadership") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                        <h6 className="ms-2 dash_text">Leadership</h6>
                                    </div>
                                </Link>
                            </div>
                        </Collapse>
                        <div className="mt-2 mx-4 ps-4">
                            <div className={location.pathname.includes("get-involve") ? "mt-2 d-flex nav-link active-link-text" : "mt-2 d-flex nav-link"} onClick={handleInvolveClick}>
                                <VolunteerActivismIcon />
                                <h6 className="ms-2 dash_text">Get Involved</h6>
                                {openInvolved ? <ExpandLessIcon className="ms-4" /> : <ExpandMoreIcon className="ms-4" />}
                            </div>
                        </div>
                        <Collapse className="bg-white" style={{ marginLeft: "20px" }} in={openInvolved}>
                            <div className="mt-2 mx-4 ps-1">
                                <Link to={pageRoutes.sponserChild} className="link">
                                    <div className={location.pathname.includes("sponser-child") ? "d-flex nav-link active-link-text" : "d-flex nav-link"}>
                                        {/* <VolunteerActivismIcon /> */}
                                        <h6 className="ms-2 dash_text">Sponser-Child</h6>
                                    </div>
                                </Link>
                            </div>
                        </Collapse>
                    </Collapse>
                </CardContent>
            </Card>
        </>
    );
};
export default SideBar;

