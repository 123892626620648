import React, { useCallback, useEffect, useState, useRef } from "react";
import SideBar from "./Sidebar";
import { Card, CardContent, IconButton, Button, TextField, Box, Dialog } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import "../../src/custom.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Logout from "./Logout";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { appConstants } from "../apiKit/appConstant";
import Swal from "sweetalert2";
import Dropdown from "./Dropdown";
import apiFunctions from "../apiKit/api";
import Loader from "./Loader";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import { fetchAPIs } from "../redux/slice/slice";
import { useDispatch } from "react-redux";

const FaqSection = () => {
    const [isOpen, setIsopen] = useState(true);
    const [faqs, setFaqs] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [Title, setTitle] = useState("");
    const [subTitle, setSubtitle] = useState("");
    const [faqForm, setFaqForm] = useState({ question: "", answer: "" });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const dispatch = useDispatch();

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
    };

    const contentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const handleOpen = () => {
        setIsopen(!isOpen);
    };

    const handleModalOpen = (index = null) => {
        setEditIndex(index);
        if (index !== null) {
            setFaqForm(faqs[index]);
            // Convert answer HTML back to editor state
            const contentBlock = convertFromHTML(faqs[index].answer);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
        } else {
            setFaqForm({ question: "", answer: "" });
            setEditorState(EditorState.createEmpty());
        }
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setEditorState(EditorState.createEmpty());
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFaqForm({ ...faqForm, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        faqForm.answer = contentAsHTML; // Assign HTML content to faqForm answer
        if (editIndex !== null) {
            const updatedFaqs = [...faqs];
            updatedFaqs[editIndex] = faqForm;
            handleupdateFAQ(faqForm);
        } else {
            handleAddFAQ(faqForm);
        }
    };

    const handleAddFAQ = (data) => {
        apiFunctions.insertFAQApi(data).then((res) => {
            if (res.status === 200) {
                fetchFAQ();
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Additional logic
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
            handleModalClose();
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    };

    const handleupdateFAQ = (data) => {
        apiFunctions.updateFAQApi(data).then((res) => {
            if (res.status === 200) {
                fetchFAQ();
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Additional logic
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
            handleModalClose();
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    };

    const handleDelete = (index, faqId) => {
        var delJson = {
            "id": faqId
        }
        Swal.fire({
            text: appConstants.deleteConfirmfaq,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: 'swal2-confirm',
                cancelButton: 'swal2-cancel'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deleteFAQApi(delJson).then((res) => {
                    if (res.status === 200) {
                        fetchFAQ();
                        Swal.fire({
                            text: res.message,
                            icon: 'success'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // Additional logic
                            }
                        });
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error'
                        });
                    }
                    handleModalClose();
                }).catch((err) => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error'
                    });
                });
            }
        });
    };

    // const fetchFAQ = useCallback(async () => {
    //     apiFunctions.getFAQApi().then((res) => {
    //         if (res.status === 200) {
    //             setFaqs(res.data?.faqs);
    //         } else {
    //             console.log("Failed to fetch faq");
    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //     });
    // }, []);
    // Ref to track mounted status
    const isMounted = useRef(false);
    // Fetch FAQs
    const fetchFAQ = useCallback(async () => {
        try {
            const res = await apiFunctions.getFAQApi();
            if (res.status === 200 && isMounted.current) {
                setFaqs(res.data?.faqs || []);
                const cmscontent = JSON.parse(res?.data?.homePageData?.[0]?.content);
                setTitle(cmscontent?.title);
                setSubtitle(cmscontent?.subtitle);
            } else {
                console.log("Failed to fetch FAQs");
            }
        } catch (err) {
            console.error("Error fetching FAQs:", err);
        }
    }, []);
    // Effect to handle component mount and unmount
    useEffect(() => {
        isMounted.current = true;
        fetchFAQ();

        const timer = setTimeout(() => {
            if (isMounted.current) {
                setIsLoading(false);
            }
        }, 2000); // Simulate loading

        return () => {
            isMounted.current = false;
            clearTimeout(timer);
        };
    }, [fetchFAQ]);

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "faq",
            "content": {
                "title": Title,
                "subtitle": subTitle
            }
        }
        apiFunctions.updateCmsHomeFAQcontentapi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2 mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className="row sub-menu">
                                    <div className="col-12">
                                        <Card className="p-lg-2 p-1">
                                            <CardContent>
                                                <div className="row mb-3">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="mb-2">
                                                            <div className="col-lg-6 col-12">
                                                                <label>Title</label>
                                                                <input value={Title} onChange={(e) => setTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                            </div>
                                                            <div className="col-lg-6 col-12">
                                                                <label>Sub Title</label>
                                                                <input value={subTitle} onChange={(e) => setSubtitle(e.target.value)} className="form-control" type="text" placeholder="Sub Title" />
                                                            </div>
                                                        </div>
                                                        <div className="text-end">
                                                            <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-6 text-start">
                                                        <h4 className="fw-bold">FAQ</h4>
                                                    </div>
                                                    <div className="col-lg-6 text-end">
                                                        <Button className="btn" onClick={() => handleModalOpen()} variant="contained" endIcon={<AddIcon />}>Add New</Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    {faqs.map((faq, index) => (
                                                        <Accordion key={index}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={`panel${index}-content`}
                                                                id={`panel${index}-header`}
                                                            >
                                                                <Typography className="fw-bold">{faq.question}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography
                                                                    component="div"
                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}
                                                                />
                                                                <div className="d-flex justify-content-end">
                                                                    <IconButton onClick={() => handleModalOpen(index)}><EditIcon /></IconButton>
                                                                    <IconButton onClick={() => handleDelete(index, faq.id)}><DeleteIcon /></IconButton>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))}
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box className="modal-box p-3">
                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            {editIndex !== null ? "Edit FAQ" : "Add New FAQ"}
                        </Typography>
                        <IconButton onClick={handleModalClose} >
                            <CloseRoundedIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                        <label>Question</label>
                        <TextField
                            name="question"
                            value={faqForm.question}
                            onChange={handleInputChange}
                            label="Question"
                            variant="outlined"
                            fullWidth
                        />
                        <label>Answer</label>
                        <div className="border border-rounded">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={onEditorStateChange}
                            // toolbar={{
                            //     options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history'],
                            // }}
                            />
                        </div>
                        <Button type="submit" className="btn" variant="contained">{editIndex !== null ? "Update" : "Add"} FAQ</Button>
                    </form>
                </Box>
            </Dialog>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </>
    );
};

export default FaqSection;
