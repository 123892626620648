import React, { useEffect, useState } from "react";
import SideBar from "../Sidebar"
import { Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import "../../../src/assets/css/cms.css";
import Logout from "../Logout";
import { useSelector, useDispatch } from 'react-redux';


import Loader from "../Loader";
import { fetchAPIs } from "../../redux/slice/slice";
import apiFunctions from "../../apiKit/api";
import Swal from "sweetalert2";
import ReduxComponent from "../reduxData";
import Dropdown from "../Dropdown";
const Header = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { headerdata } = useSelector((state) => state.api);
    const dispatch = useDispatch();

    // variables
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [instaLink, setInstaLink] = useState("");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [logo, setLogo] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [menuBtn, setMenuBtn] = useState("");
    const [searchBtn, setSearchBtn] = useState("");
    const [loginBtn, setLoginBtn] = useState("");
    const [loginLink, setLoginLink] = useState("");
    const [registerBtn, setRegisterBtn] = useState("");
    const [registerLink, setRegisterLink] = useState("");
    const [sponserBtn, setSponserBtn] = useState("");
    const [sponserLink, setSponserLink] = useState("");
    const [cartBtn, setCartBtn] = useState("");
    const [cartLink, setCartLink] = useState("");

    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const setHeaderData = async (data) => {
        console.log(data?.images?.headerImage, 'heade');

        setPhone(data?.contents?.phonNumber);
        setEmail(data?.contents?.email);
        setYoutubeLink(data?.contents?.youtubeLink);
        setFacebook(data?.contents?.facebookLink);
        setLinkedin(data?.contents?.linkedinLink);
        setInstaLink(data?.contents?.instagramLink);
        setLogo(data?.images?.headerImage);
        setMenuBtn(data?.contents?.buttonContent?.menubtn);
        setSearchBtn(data?.contents?.buttonContent?.search);
        setCartBtn(data?.contents?.buttonContent?.cartbtn);
        setCartLink(data?.contents?.buttonContent?.cartlink);
        setLoginBtn(data?.contents?.buttonContent?.loginbtn);
        setLoginLink(data?.contents?.buttonContent?.loginlink);
        setRegisterBtn(data?.contents?.buttonContent?.registerbtn);
        setRegisterLink(data?.contents?.buttonContent?.registerlink);
        setSponserBtn(data?.contents?.buttonContent?.sponsbtn);
        setSponserLink(data?.contents?.buttonContent?.sponslink);
    }

  

    useEffect(() => {
        setHeaderData(headerdata[0]);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(headerdata, 'headerdata');
    }, [headerdata])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("fieldName", "header");
        // Contents object fields
        var json = {
            "email": email,
            "phonNumber": phone,
            "youtubeLink": youtubeLink,
            "facebookLink": facebook,
            "linkedinLink": linkedin,
            "instagramLink": instaLink,
            "buttonContent": {
                "menubtn": menuBtn,
                "search": searchBtn,
                "loginbtn": loginBtn,
                "loginlink": loginLink,
                "registerbtn": registerBtn,
                "registerlink": registerLink,
                "sponsbtn": sponserBtn,
                "sponslink": sponserLink,
                "cartbtn": cartBtn,
                "cartlink": cartLink
            }
        }
        formData.append("contents", JSON.stringify(json));
        if (logoFile instanceof File) {
            formData.append("headerImage", logoFile);
        }
        apiFunctions.CmsHeaderFooterUpdateapi(formData).then((res) => {
            if (res.status === 200) {
                console.log("success");
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >

                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className=" row sub-menu p-0">
                                    <div className=" col-12">
                                        <Card>
                                            <CardContent>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="container">
                                                        {/* Top headers */}
                                                        <div>
                                                            <div className="text-start mt-3"><h5 className="fw-bold">Header Contents</h5></div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="phone" className="sr-only">Phone</label>
                                                                    <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" id="phone" placeholder="Phone" />
                                                                </div>
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="email" className="sr-only">Email</label>
                                                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" placeholder="Email" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                        </div>

                                                        {/* Links */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-3">Links</h6></div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="facebook" className="sr-only">facebook</label>
                                                                    <input type="text" value={facebook} onChange={(e) => setFacebook(e.target.value)} className="form-control" id="facebook" placeholder="facebook link" />
                                                                </div>
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="linkedin" className="sr-only">LinkedIn</label>
                                                                    <input type="text" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} className="form-control" id="linkedin" placeholder="linkedin link" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                            <div className="row mt-3">
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="instagram" className="sr-only">Instagram</label>
                                                                    <input type="text" value={instaLink} onChange={(e) => setInstaLink(e.target.value)} className="form-control" id="instagram" placeholder="Instagram link" />
                                                                </div>
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="youtube" className="sr-only">Youtube</label>
                                                                    <input type="text" value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} className="form-control" id="youtube" placeholder="Youtube link" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                        </div>

                                                        {/* Logo */}
                                                        {/* <div className="row mt-2">
                                                            <div className="text-start"><p className="fw-bold mt-1">Logo</p></div>
                                                            <div className="d-flex">
                                                                <div style={{ width: "150px" }}>
                                                                    <img className="input-img" src={logo} alt="demo" />
                                                                </div>
                                                                <input
                                                                    className="form-control foot-input"
                                                                    onChange={handleLogoChange}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg"
                                                                />
                                                            </div>
                                                        </div> */}

                                                        {/* Buttons */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-3">Buttons</h6></div>
                                                            <div className="row mt-3">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Menu:</h6>
                                                                    </div>
                                                                    <input type="text" value={menuBtn} onChange={(e) => setMenuBtn(e.target.value)} className="form-control ms-2 foot-input" id="Menu" placeholder="Menu" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Search:</h6>
                                                                    </div>
                                                                    <input type="text" value={searchBtn} onChange={(e) => setSearchBtn(e.target.value)} className="form-control ms-2 foot-input" id="Search" placeholder="Search" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Login:</h6>
                                                                    </div>
                                                                    <input type="text" value={loginBtn} onChange={(e) => setLoginBtn(e.target.value)} className="form-control ms-2 foot-input" id="Login" placeholder="Login" />
                                                                    <input type="text" value={loginLink} onChange={(e) => setLoginLink(e.target.value)} className="form-control ms-2 foot-input" id="Login" placeholder="Login Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Register:</h6>
                                                                    </div>
                                                                    <input type="text" value={registerBtn} onChange={(e) => setRegisterBtn(e.target.value)} className="form-control ms-2 foot-input" id="Register" placeholder="Register" />
                                                                    <input type="text" value={registerLink} onChange={(e) => setRegisterLink(e.target.value)} className="form-control ms-2 foot-input" id="Register" placeholder="Register Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Sponser A Child:</h6>
                                                                    </div>
                                                                    <input type="text" value={sponserBtn} onChange={(e) => setSponserBtn(e.target.value)} className="form-control ms-2 foot-input" id="Sponser A Child" placeholder="Sponser A Child" />
                                                                    <input type="text" value={sponserLink} onChange={(e) => setSponserLink(e.target.value)} className="form-control ms-2 foot-input" id="Sponser A Child" placeholder="Sponser A Child Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-3 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Cart:</h6>
                                                                    </div>
                                                                    <input type="text" value={cartBtn} onChange={(e) => setCartBtn(e.target.value)} className="form-control ms-2 foot-input" id="Cart" placeholder="Cart" />
                                                                    <input type="text" value={cartLink} onChange={(e) => setCartLink(e.target.value)} className="form-control ms-2 foot-input" id="Cart" placeholder="Cart Link" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text-start mt-3">
                                                            <button type="submit" className="btn">Update</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </CardContent>
                                        </Card>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Header;