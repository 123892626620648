// import React from "react";
// import { DataGrid } from '@mui/x-data-grid';

// const Table = ({ rows, columns }) => {
//     // Make columns non-resizable
//     const fixedColumns = columns.map(column => ({
//         ...column,
//         resizable: false
//     }));

//     return (
//         <DataGrid
//             rows={rows}
//             columns={fixedColumns}
//             initialState={{
//                 pagination: {
//                     paginationModel: { page: 0, pageSize: 5 },
//                 },
//             }}
//             pageSizeOptions={[5, 10]}
//         />
//     );
// }

// export default Table;
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent } from "@mui/material";
import "../assets/css/table.css";

const Table = ({ rows, columns }) => {
    // Make columns non-resizable and center text
    const fixedColumns = columns.map(column => ({
        ...column,
        resizable: false,
        headerAlign: 'center',
        align: 'center',
        flex: 1, // Make columns flexible
        minWidth: 100, // Set a minimum width for each column
        hide: window.innerWidth < 768 && column.hideOnMobile ? true : false, // Conditionally hide columns on mobile
    }));

    return (
        <Card elevation={4} className="p-0">
            <CardContent className="p-0">
                <div className="table-container">
                    <DataGrid
                        rows={rows}
                        columns={fixedColumns}
                        getRowId={(row) => row.id}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        localeText={{
                            noRowsLabel: 'No data found', 
                        }}
                        pageSizeOptions={[5, 10]}
                        autoHeight
                        disableColumnMenu
                    />
                </div>
            </CardContent>
        </Card>
    );
}

export default Table;
