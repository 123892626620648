import { useDispatch, useSelector } from 'react-redux';
import { fetchAPIs } from '../redux/slice/slice';
import React, { useEffect } from 'react';
const ReduxComponent = () => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.api);

    useEffect(() => {
        dispatch(fetchAPIs());
    }, [dispatch]);

    if (loading) return <div style={{color:"transparent"}}>Loading...</div>;
    if (error) return <div style={{color:"transparent"}}>Error: {error}</div>;

    return (
        <div />
    );
};
export default ReduxComponent;
