import React, { useEffect, useState } from "react";
import SideBar from "../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import Logout from "../Logout";
import Loader from "../Loader";
import Dropdown from "../Dropdown";
import ReduxComponent from "../reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import CampaignSection from "./home/campaignSection";

const CampaignPage = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [])



    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className="row">
                                    <div className="col-12 w-100">
                                        <Card className="p-2">
                                            <CardContent>
                                                <CampaignSection />
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />


        </>
    )
}
export default CampaignPage;