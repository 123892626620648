import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../../src/custom.css";
import Logout from "../../Logout";
import Loader from "../../Loader";
import Dropdown from "../../Dropdown";
import apiFunctions from "../../../apiKit/api";
import ReduxComponent from "../../../components/reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
const HowtoWorkPage = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    //Our approach
    const [title, setTitle] = useState("");
    const [subtitle, setsubTitle] = useState("");
    const [howtitle, sethowTitle] = useState("");
    const [description, setDescription] = useState("");
    const [ourApproach, setOurapproach] = useState("");
    const [ourApproachlink, setOurapproachlink] = useState("");
    const [logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    //Programme Area
    const [programmeTitle, setProgrammeTitle] = useState("");
    const [programmeDes, setProgrammeDes] = useState("");
    const [educationTitle, setEducationTitle] = useState("");
    const [educationDes, setEducationDes] = useState("");
    const [childTitle, setChildTitle] = useState("");
    const [childDes, setChildDes] = useState("");
    const [healthTitle, setHealthTitle] = useState("");
    const [healthDes, setHealthDes] = useState("");
    const [liveTitle, setLiveTitle] = useState("");
    const [liveDes, setLiveDes] = useState("");
    const [waterTitle, setWaterTitle] = useState("");
    const [waterDes, setWaterDes] = useState("");
    const [disasterTitle, setDisasterTitle] = useState("");
    const [disaterDes, setDisaterDes] = useState("");
    const [advocTitle, setAdvocTitle] = useState("");
    const [advocDes, setAdvocDes] = useState("");
    //where-money
    const [moneyTitle, setMoneyTitle] = useState("");
    const [moneyDes, setMoneyDes] = useState("");
    const [kidsTitle, setKidsTitle] = useState("");
    const [kidsPercent, setKidsPercent] = useState("");
    const [memberTitle, setMemberTitle] = useState("");
    const [memberPercent, setMemberPercent] = useState("");
    const [essentialTitle, setEssentialTitle] = useState("");
    const [essantialPercent, setEssentialPercent] = useState("");
    const [edumonTitle, setEdumonTitle] = useState("");
    const [edumonPercent, setEdumonPercent] = useState("");
    //Get-Involved
    const [InvolveImg, setInvolveImg] = useState(null);
    const [InvolveImgFile, setInvolveImgFile] = useState(null);
    const [InvolveTitle, setInvolveTitle] = useState("");
    const [InvolveDes, setInvolveDes] = useState("");
    const [voluntTitle, setVoluntTitle] = useState("");
    const [voluntDes, setVoluntDes] = useState("");
    const [voluntBtn, setVoluntBtn] = useState("");
    const [voluntLink, setVoluntLink] = useState("");
    const [donateTitle, setDonateTitle] = useState("");
    const [donateDes, setDonateDes] = useState("");
    const [donateBtn, setDonateBtn] = useState("");
    const [donateLink, setDonateLink] = useState("");
    const [advocacyTitle, setAdvocacyTitle] = useState("");
    const [advocacyDes, setAdvocacyDes] = useState("");
    const [advocBtn, setAdvocBtn] = useState("");
    const [advocLink, setAdvocLink] = useState("");
    const { howtoworkdata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(howtoworkdata, "how to work data");
        setData(howtoworkdata);
    }, [howtoworkdata])


    const setData = (data) => {
        const approach = JSON.parse(data[0]?.content);
        const programmme = JSON.parse(data[1]?.content);
        const wheremoney = JSON.parse(data[2]?.content);
        const involve = JSON.parse(data[3]?.content);
        //approach
        setOurapproach(approach?.ourapproach);
        setOurapproachlink(approach?.ourapproachlink);
        setTitle(approach?.title);
        setsubTitle(approach?.orangetxt);
        sethowTitle(approach?.howtitle);
        setDescription(approach?.description);
        setLogo(appConstants.imageUrl + data[0]?.images);
        //programme area
        setProgrammeTitle(programmme?.programmeTitle);
        setProgrammeDes(programmme?.programmeDes);
        setEducationTitle(programmme?.educationTitle);
        setEducationDes(programmme?.educationDes);
        setChildTitle(programmme?.childTitle);
        setChildDes(programmme?.childDes);
        setHealthTitle(programmme?.healthTitle);
        setHealthDes(programmme?.healthDes);
        setLiveTitle(programmme?.liveTitle);
        setLiveDes(programmme?.liveDes);
        setWaterTitle(programmme?.waterTitle);
        setWaterDes(programmme?.waterDes);
        setDisasterTitle(programmme?.disasterTitle);
        setDisaterDes(programmme?.disaterDes);
        setAdvocTitle(programmme?.advocTitle);
        setAdvocDes(programmme?.advocDes);
        //where-money
        setMoneyTitle(wheremoney?.moneyTitle);
        setMoneyDes(wheremoney?.moneyDes);
        setKidsTitle(wheremoney?.kidsTitle);
        setKidsPercent(wheremoney?.kidsPercent);
        setMemberTitle(wheremoney?.memberTitle);
        setMemberPercent(wheremoney?.memberPercent);
        setEssentialTitle(wheremoney?.essentialTitle);
        setEssentialPercent(wheremoney?.essantialPercent);
        setEdumonTitle(wheremoney?.edumonTitle);
        setEdumonPercent(wheremoney?.edumonPercent);
        //get-involve
        setInvolveImg(involve?.edumonPercent);
        setInvolveTitle(involve?.InvolveTitle);
        setInvolveDes(involve?.InvolveDes);
        setVoluntTitle(involve?.voluntTitle);
        setVoluntDes(involve?.voluntDes);
        setVoluntBtn(involve?.voluntBtn);
        setVoluntLink(involve?.voluntLink);
        setDonateTitle(involve?.donateTitle);
        setDonateDes(involve?.donateDes);
        setDonateBtn(involve?.donateBtn);
        setDonateLink(involve?.donateLink);
        setAdvocacyTitle(involve?.advocacyTitle);
        setAdvocacyDes(involve?.advocacyDes);
        setAdvocBtn(involve?.advocBtn);
        setAdvocLink(involve?.advocLink);
        setInvolveImg(appConstants.imageUrl + data[3]?.images);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": title,
            "orangetxt": subtitle,
            "howtitle": howtitle,
            "description": description,
            "ourapproach": ourApproach,
            "ourapproachlink": ourApproachlink
        }
        if (LogoFile) {
            form.append("image", LogoFile)
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsAboutOurapproach(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }


    const handleProgrammeSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "programmeTitle": programmeTitle,
            "programmeDes": programmeDes,
            "educationTitle": educationTitle,
            "educationDes": educationDes,
            "childTitle": childTitle,
            "childDes": childDes,
            "healthTitle": healthTitle,
            "healthDes": healthDes,
            "liveTitle": liveTitle,
            "liveDes": liveDes,
            "waterTitle": waterTitle,
            "waterDes": waterDes,
            "disasterTitle": disasterTitle,
            "disaterDes": disaterDes,
            "advocTitle": advocTitle,
            "advocDes": advocDes
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsAboutProgramme(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    const handleMoneySubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "moneyTitle": moneyTitle,
            "moneyDes": moneyDes,
            "kidsTitle": kidsTitle,
            "kidsPercent": kidsPercent,
            "memberTitle": memberTitle,
            "memberPercent": memberPercent,
            "essentialTitle": essentialTitle,
            "essantialPercent": essantialPercent,
            "edumonTitle": edumonTitle,
            "edumonPercent": edumonPercent
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsAboutWheremoney(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    const handleInvolveSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "InvolveTitle": InvolveTitle,
            "InvolveDes": InvolveDes,
            "voluntTitle": voluntTitle,
            "voluntDes": voluntDes,
            "voluntBtn": voluntBtn,
            "voluntLink": voluntLink,
            "donateTitle": donateTitle,
            "donateDes": donateDes,
            "donateBtn": donateBtn,
            "donateLink": donateLink,
            "advocacyTitle": advocacyTitle,
            "advocacyDes": advocacyDes,
            "advocBtn": advocBtn,
            "advocLink": advocLink
        }
        if (InvolveImgFile) {
            form.append("image", InvolveImgFile)
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsAboutGetinvolve(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row mt-3 main">
                                <Card className="p-2">
                                    <CardContent>
                                        {/* How we work section */}
                                        <div className="wework-section">
                                            <h5><b>How we work contents</b></h5>
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Logo</label>
                                                            <div>
                                                                {logo ?
                                                                    <div style={{ width: "300px" }}>
                                                                        <img className="input-img" src={logo} alt="logo" />
                                                                    </div> : ''}
                                                                <div className="mt-2 d-flex">
                                                                    <input
                                                                        className="form-control foot-input"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file) {
                                                                                const objectUrl = URL.createObjectURL(file);
                                                                                setLogoFile(file);
                                                                                setLogo(objectUrl);
                                                                            }
                                                                        }}
                                                                        type="file"
                                                                        accept="image/png, image/jpeg,image/webp"
                                                                    />
                                                                    <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>How We Work</label>
                                                            <input value={howtitle} onChange={(e) => sethowTitle(e.target.value)} type="text" className="form-control" placeholder="How We Work" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-3 col-12">
                                                            <label>Our Approach</label>
                                                            <input value={ourApproach} onChange={(e) => setOurapproach(e.target.value)} type="text" className="form-control" placeholder="Our Approach" />
                                                        </div>
                                                        <div className="col-lg-3  col-12">
                                                            <label style={{ color: "transparent" }} >Our Approach</label>
                                                            <input value={ourApproachlink} onChange={(e) => setOurapproachlink(e.target.value)} type="text" className="form-control" placeholder="Our Approach Link" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Transforming Lives</label>
                                                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Transforming Lives" />
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <label>Partnership</label>
                                                            <input value={subtitle} onChange={(e) => setsubTitle(e.target.value)} type="text" className="form-control" placeholder="Partnership" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <h5><b>Description</b></h5>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows={3} className="form-control" placeholder="description" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="text-start">
                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                        {/* Programme area section */}
                                        <form onSubmit={handleProgrammeSubmit}>
                                            <div className="programme-area mt-5" >
                                                <h5><b>Programme Area contents</b></h5>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Programme Area</label>
                                                            <input value={programmeTitle} onChange={(e) => setProgrammeTitle(e.target.value)} type="text" className="form-control" placeholder="Programme Area" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <h5><b>Description</b></h5>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-12">
                                                            <textarea value={programmeDes} onChange={(e) => setProgrammeDes(e.target.value)} rows={3} className="form-control" placeholder="Programme Area description" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* cards */}
                                            <div className="programme-area-cards mt-4">
                                                <div className="row">
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Education</label>
                                                            <input value={educationTitle} onChange={(e) => setEducationTitle(e.target.value)} type="text" className="form-control" placeholder="Education" />
                                                            <textarea value={educationDes} onChange={(e) => setEducationDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Education description" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Child Protection</label>
                                                            <input value={childTitle} onChange={(e) => setChildTitle(e.target.value)} type="text" className="form-control" placeholder="Child Protection" />
                                                            <textarea value={childDes} onChange={(e) => setChildDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Child Protection description" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Health & Nutrician</label>
                                                            <input value={healthTitle} onChange={(e) => setHealthTitle(e.target.value)} type="text" className="form-control" placeholder="Health" />
                                                            <textarea value={healthDes} onChange={(e) => setHealthDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Health description" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>livelihoods</label>
                                                            <input value={liveTitle} onChange={(e) => setLiveTitle(e.target.value)} type="text" className="form-control" placeholder="livelihoods" />
                                                            <textarea value={liveDes} onChange={(e) => setLiveDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="livelihoods description" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Water</label>
                                                            <input value={waterTitle} onChange={(e) => setWaterTitle(e.target.value)} type="text" className="form-control" placeholder="Water" />
                                                            <textarea value={waterDes} onChange={(e) => setWaterDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Water description" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Disaster</label>
                                                            <input value={disasterTitle} onChange={(e) => setDisasterTitle(e.target.value)} type="text" className="form-control" placeholder="Disaster" />
                                                            <textarea value={disaterDes} onChange={(e) => setDisaterDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Disaster description" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Advocacy</label>
                                                            <input value={advocTitle} onChange={(e) => setAdvocTitle(e.target.value)} type="text" className="form-control" placeholder="Advocacy" />
                                                            <textarea value={advocDes} onChange={(e) => setAdvocDes(e.target.value)} rows={3} className="form-control mt-2" placeholder="Advocacy description" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-start">
                                                <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                            </div>
                                        </form>

                                        {/* Where your money goes */}
                                        <div className="whereyour-section mt-4 mb-3">
                                            <h5><b>Where Your money contents</b></h5>
                                            <form onSubmit={handleMoneySubmit}>
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <label>Title</label>
                                                        <input className="form-control" value={moneyTitle} onChange={(e) => setMoneyTitle(e.target.value)} type="text" placeholder="Where Your money" />
                                                        <textarea value={moneyDes} onChange={(e) => setMoneyDes(e.target.value)} rows={3} className="form-control mt-2" type="text" placeholder="description" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Kids Education</label>
                                                            <input value={kidsTitle} onChange={(e) => setKidsTitle(e.target.value)} type="text" className="form-control" placeholder="Kids Education" />
                                                            <input value={kidsPercent} onChange={(e) => setKidsPercent(e.target.value)} className="form-control mt-2" placeholder="Kids Education percentage" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Memberships</label>
                                                            <input value={memberTitle} onChange={(e) => setMemberTitle(e.target.value)} type="text" className="form-control" placeholder="Memberships" />
                                                            <input value={memberPercent} onChange={(e) => setMemberPercent(e.target.value)} className="form-control mt-2" placeholder="Memberships percentage" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Essential Meds</label>
                                                            <input value={essentialTitle} onChange={(e) => setEssentialTitle(e.target.value)} type="text" className="form-control" placeholder="Essential" />
                                                            <input value={essantialPercent} onChange={(e) => setEssentialPercent(e.target.value)} className="form-control mt-2" placeholder="Essential percentage" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-12">
                                                        <div>
                                                            <label>Educations</label>
                                                            <input value={edumonTitle} onChange={(e) => setEdumonTitle(e.target.value)} type="text" className="form-control" placeholder="Educations" />
                                                            <input value={edumonPercent} onChange={(e) => setEdumonPercent(e.target.value)} className="form-control mt-2" placeholder="Educations percentage" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="text-start">
                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                </div>
                                            </form>
                                        </div>

                                        {/* get involved */}
                                        <div className="getinvolved-section mt-4 mb-3">
                                            <h5><b>Get Involved contents</b></h5>
                                            <form onSubmit={handleInvolveSubmit}>
                                                <div>
                                                    {InvolveImg ?
                                                        <div style={{ width: "150px" }}>
                                                            <img className="input-img" src={InvolveImg} alt="Logo" />
                                                        </div> : ''}
                                                    <div className="d-flex mt-2">
                                                        <input
                                                            className="form-control foot-input"
                                                            onChange={(e) => {
                                                                const file = e.target.files[0];
                                                                if (file) {
                                                                    const objectUrl = URL.createObjectURL(file);
                                                                    setInvolveImgFile(file);
                                                                    setInvolveImg(objectUrl);
                                                                }
                                                            }}
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                        />
                                                        <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <label>Title</label>
                                                        <input className="form-control" value={InvolveTitle} onChange={(e) => setInvolveTitle(e.target.value)} type="text" placeholder="Get Involved" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6 col-12">
                                                        <label>Description</label>
                                                        <textarea rows={3} className="form-control" value={InvolveDes} onChange={(e) => setInvolveDes(e.target.value)} type="text" placeholder="Get Involved" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <label><b>Volunteer</b></label>
                                                        <input value={voluntTitle} onChange={(e) => setVoluntTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                    </div>
                                                    <div className="col-12">
                                                        <label>description</label>
                                                        <textarea value={voluntDes} onChange={(e) => setVoluntDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-lg-6 col-12">
                                                        <input value={voluntBtn} onChange={(e) => setVoluntBtn(e.target.value)} className="form-control" type="text" placeholder="Volunteer" />
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <input value={voluntLink} onChange={(e) => setVoluntLink(e.target.value)} className="form-control" type="text" placeholder="VolunteerLink" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <label><b>Donate</b></label>
                                                        <input value={donateTitle} onChange={(e) => setDonateTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                    </div>
                                                    <div className="col-12">
                                                        <label>description</label>
                                                        <textarea value={donateDes} onChange={(e) => setDonateDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6 col-12">
                                                        <input value={donateBtn} onChange={(e) => setDonateBtn(e.target.value)} className="form-control" type="text" placeholder="Donate" />
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <input value={donateLink} onChange={(e) => setDonateLink(e.target.value)} className="form-control" type="text" placeholder="DonateLink" />
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-6">
                                                        <label><b>Advocate</b></label>
                                                        <input value={advocacyTitle} onChange={(e) => setAdvocacyTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                    </div>
                                                    <div className="col-12">
                                                        <label>description</label>
                                                        <textarea value={advocacyDes} onChange={(e) => setAdvocacyDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-lg-6 col-12">
                                                        <input value={advocBtn} onChange={(e) => setAdvocBtn(e.target.value)} className="form-control" type="text" placeholder="Contact us" />
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <input value={advocLink} onChange={(e) => setAdvocLink(e.target.value)} className="form-control" type="text" placeholder="Contact us Link" />
                                                    </div>
                                                </div>
                                                <div className="text-end">
                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </CardContent>
                                </Card>


                            </div>
                        }

                    </div>
                </div>
            </div >
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default HowtoWorkPage;