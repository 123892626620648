// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-btn{
    background-color: #1e4cf3;
    color: white;
    border: 0px;
    border-radius: 20px;
    height: 45px;
    text-align: center;
}
.input-img{
    height: 80px !important;
    width: 220px !important;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/cms.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".input-btn{\n    background-color: #1e4cf3;\n    color: white;\n    border: 0px;\n    border-radius: 20px;\n    height: 45px;\n    text-align: center;\n}\n.input-img{\n    height: 80px !important;\n    width: 220px !important;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
