import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiFunctions from "../../../apiKit/api";
import Swal from "sweetalert2";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
const CampaignSection = () => {
    const [Logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const dispatch = useDispatch();
    const { campaigndata } = useSelector((state) => state.api);

    // variables
    const [campaignTitle, setCampaignTitle] = useState("");
    const [campaignDes, setcampaignDes] = useState("");
    const [donateBtn, setdonateBtn] = useState("");
    const [donatelink, setdonateLink] = useState("");

    const setData = async (data, image) => {
        setLogo(appConstants.imageUrl + image)
        setCampaignTitle(data?.title);
        setcampaignDes(data?.description);
        setdonateBtn(data?.donatebtn);
        setdonateLink(data?.donatelink);
    }

    useEffect(() => {
        setData(JSON.parse(campaigndata[0]?.content), campaigndata[0]?.images);
    }, [campaigndata])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        // formData.append("fieldName", "wvi-journey");
        var json = {
            "title": campaignTitle,
            "description": campaignDes,
            "donatebtn": donateBtn,
            "donatelink": donatelink
        }
        formData.append("contents", JSON.stringify(json));
        if (LogoFile instanceof File) {
            formData.append("image", LogoFile);
        }
        apiFunctions.updateCmsHomeCampaignapi(formData).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-lg-2 p-1">
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <h6 className="fw-bold mt-3">Campaign Banner section</h6>
                                    {Logo?
                                    <div style={{ width: "150px" }}>
                                        <img className="input-img" src={Logo} alt="demo" />
                                    </div>:''}
                                    <div className="d-flex mt-2 multi-img">
                                        <input
                                            className="form-control foot-input"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const objectUrl = URL.createObjectURL(file);
                                                    setLogoFile(file);
                                                    setLogo(objectUrl);
                                                }
                                            }}
                                            type="file"
                                            accept="image/png, image/jpeg"
                                        />
                                        <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12">
                                        <label>Title</label>
                                        <input className="form-control" value={campaignTitle} onChange={(e) => setCampaignTitle(e.target.value)} type="text" placeholder="Title" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>description</label>
                                        <textarea rows={3} className="form-control" value={campaignDes} onChange={(e) => setcampaignDes(e.target.value)} type="text" placeholder="description" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-6 col-12">
                                        <input className="form-control" value={donateBtn} onChange={(e) => setdonateBtn(e.target.value)} type="text" placeholder="Donate" />
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <input className="form-control" value={donatelink} onChange={(e) => setdonateLink(e.target.value)} type="text" placeholder="DonateLink" />
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default CampaignSection