// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Poppins-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table.css */
.table-container {
    width: 100%;
    overflow-x: auto;
    /* Enable horizontal scroll on small screens */
    display: flex;
    justify-content: center;
}

@font-face {
    font-family: 'poppin-light';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}

.MuiDataGrid-root {
    width: 100%;
    font-family: 'poppin-light' !important;
}

/* Adjust styles for small screens */
@media only screen and (max-width: 768px) {
    .MuiDataGrid-root {
        font-size: 0.8rem;
        /* Reduce font size */
    }

    .MuiDataGrid-cell,
    .MuiDataGrid-columnHeader {
        padding-left: 8px;
        padding-right: 8px;
    }

    .MuiDataGrid-columnHeaderTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* Prevent text wrapping */
    }
}

.css-de9k3v-MuiDataGrid-selectedRowCount {
    color: transparent !important;
}

.css-yrdy0g-MuiDataGrid-columnHeaderRow {
    /* background-color: #ff5555 !important; */
    background-color: rgba(254, 97, 0, 255)  !important;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/table.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,WAAW;IACX,gBAAgB;IAChB,8CAA8C;IAC9C,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;IAC3B,+DAAyD;AAC7D;;AAEA;IACI,WAAW;IACX,sCAAsC;AAC1C;;AAEA,oCAAoC;AACpC;IACI;QACI,iBAAiB;QACjB,qBAAqB;IACzB;;IAEA;;QAEI,iBAAiB;QACjB,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;QAChB,uBAAuB;QACvB,mBAAmB;QACnB,0BAA0B;IAC9B;AACJ;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,0CAA0C;IAC1C,mDAAmD;IACnD,YAAY;AAChB","sourcesContent":["/* Table.css */\n.table-container {\n    width: 100%;\n    overflow-x: auto;\n    /* Enable horizontal scroll on small screens */\n    display: flex;\n    justify-content: center;\n}\n\n@font-face {\n    font-family: 'poppin-light';\n    src: url(\"../fonts/Poppins-Light.otf\") format('truetype');\n}\n\n.MuiDataGrid-root {\n    width: 100%;\n    font-family: 'poppin-light' !important;\n}\n\n/* Adjust styles for small screens */\n@media only screen and (max-width: 768px) {\n    .MuiDataGrid-root {\n        font-size: 0.8rem;\n        /* Reduce font size */\n    }\n\n    .MuiDataGrid-cell,\n    .MuiDataGrid-columnHeader {\n        padding-left: 8px;\n        padding-right: 8px;\n    }\n\n    .MuiDataGrid-columnHeaderTitle {\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n        /* Prevent text wrapping */\n    }\n}\n\n.css-de9k3v-MuiDataGrid-selectedRowCount {\n    color: transparent !important;\n}\n\n.css-yrdy0g-MuiDataGrid-columnHeaderRow {\n    /* background-color: #ff5555 !important; */\n    background-color: rgba(254, 97, 0, 255)  !important;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
