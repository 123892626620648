import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import Logout from "../components/Logout";
import Loader from "../components/Loader";
import Dropdown from "../components/Dropdown";
import apiFunctions from "../apiKit/api";
import ReduxComponent from "./reduxComponent";
import { Button, ButtonGroup, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Switch } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from "sweetalert2";
import Table from "../../src/components/Table"
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../redux/slice/slice";
import { appConstants } from "../apiKit/appConstant";
const MyWorldBanner = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [])

    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({
        description: {
            title: '',
            description: ''
        },
        status: 'active',
    });
    const [filteredRows, setFilteredRows] = useState([]);
    const [banners, setBanners] = useState([]);
    const [Image, setImage] = useState(null);
    const [ImageFile, setImageFile] = useState(null);

    const [WorldTitle, setWorldTitle] = useState("");
    const [WorldImage, setWorldImage] = useState(null);
    const [WorldImageFile, setWorldImageFile] = useState(null);
    const { worldbannerdata, worldContentdata } = useSelector((state) => state.api);
    const dispatch = useDispatch();

    useEffect(() => {
        const parsedData = worldbannerdata?.images?.map(item => {
            try {
                const parsedDescription = typeof item.description === 'string' ? JSON.parse(item.description) : item.description;
                return {
                    ...item,
                    description: parsedDescription
                };
            } catch (error) {
                console.error("Failed to parse description:", error);
                return item;
            }
        }) || [];
        setBanners(parsedData);
        const parsedContent = JSON.parse(worldContentdata[0]?.content);
        setWorldTitle(parsedContent.title);
        setWorldImage(appConstants.imageUrl + worldContentdata[0]?.images);
        console.log(worldContentdata, 'worldContentdata');

    }, [worldbannerdata, worldContentdata]);
    // Columns for the table
    const columns = [
        {
            field: 'imageUrl',
            headerName: 'Image',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                params.row && (
                    <img
                        src={appConstants.imageUrl + params.row.imageUrl}
                        alt={params.row.description?.title || 'Image'}
                        width={100}
                    />
                ) // Safely render the image if params.row exists
        },
        {
            field: 'description.title',
            headerName: 'Title',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.title : '-'
        },
        {
            field: 'description.description',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.description : '-'
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                params.row && (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                        <IconButton onClick={() => handleRemove(params.row)}><DeleteIcon /></IconButton>
                    </ButtonGroup>
                )
            ) // Safely render the action buttons if params.row exists
        },
    ];

    // Function to handle Delete
    const handleRemove = (row) => {
        Swal.fire({
            text: `Are you sure you want to delete?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deleteMyworldBannerapi([row.id]).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        dispatch(fetchAPIs());
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
            }
        });
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleEditSave = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", selectedRow?.status);
        form.append("id", selectedRow?.id);
        form.append("description", JSON.stringify(json))
        apiFunctions.updateMyworldBannerapi(form).then((res) => {
            if (res.status === 200) {
                handleEditClose()
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                handleEditClose();
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            handleEditClose()
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });

        // setIsEditOpen(false);
        // setSelectedRow(null);
        // Swal.fire('', 'Saved Successfully', 'success');
    };

    const handleAddOpen = () => {
        setSelectedRow({
            description: {
                title: '',
                description: ''
            },
            status: 'active',
        });
        setIsAddOpen(true);
    };

    const handleAddClose = () => {
        setIsAddOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleAddSave = (e) => {
        e.preventDefault();
        setFilteredRows([...filteredRows, { ...selectedRow, id: banners.length + 1 }]);
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", "active")
        form.append("description", JSON.stringify(json))
        apiFunctions.addMyworldBannerapi(form).then((res) => {
            if (res.status === 200) {
                handleAddClose()
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                handleAddClose();
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            handleAddClose()
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });

    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleWorldFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setWorldImageFile(file);
            const reader = new FileReader();
            reader.onload = () => {
                setWorldImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": WorldTitle,
        }
        if (WorldImageFile) {
            form.append("image", WorldImageFile);
        }
        console.log(WorldImageFile, "WorldImageFile");
        form.append("contents", JSON.stringify(json))
        apiFunctions.updateCmsMyworldcontentapi(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className="row">
                                    <div className="col-12 w-100">
                                        <Card className="p-2">
                                            <CardContent>
                                                <div className="text-start"><h5 className="fw-bold">My World Banner</h5></div>
                                                <form onSubmit={handleFormSubmit}>
                                                    <div className="mb-3 mt-3">
                                                        <label>Title</label>
                                                        <input value={WorldTitle} onChange={(e) => setWorldTitle(e.target.value)} className="form-control w-50" placeholder="Title" />
                                                    </div>
                                                    <div className="mb-3 mt-3">
                                                        <label>Banner-Image</label><br />
                                                        <img
                                                            style={{ width: "500px", height: "350px", marginTop: "16px" }}
                                                            className="rounded"
                                                            src={WorldImage}
                                                            alt="demo"
                                                        />
                                                        <div className="d-flex mt-2">
                                                            <input
                                                                className="form-control foot-input"
                                                                onChange={handleWorldFileChange}
                                                                type="file"
                                                                accept="image/png, image/jpeg,image/webp"
                                                            />
                                                            <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px",color:"grey" }}>(690×700)</span>
                                                        </div>
                                                    </div>
                                                    <button className="btn mt-3" type="submit">Save</button>
                                                </form>
                                                <div className="text-end">
                                                    <Button className="btn mb-2" onClick={handleAddOpen} endIcon={<AddIcon />} variant="contained">Add new</Button>
                                                </div>
                                                <Table rows={banners} columns={columns} />
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                                {/* View Modal */}
                                <Dialog open={isViewOpen} onClose={handleViewClose} maxWidth={"md"} fullWidth={true}>
                                    <DialogTitle>View Banner</DialogTitle>
                                    <DialogContent>
                                        <img src={appConstants.imageUrl + selectedRow?.imageUrl} alt={selectedRow?.description?.title} width="100%" style={{ width: "300px", height: "240px", marginTop: '10px' }} />
                                        <table className="table table-bordered mt-2">
                                            <tbody>
                                                <tr>
                                                    <td><p><strong>Title:</strong></p></td>
                                                    <td><p>{selectedRow?.description?.title}</p></td>
                                                </tr>
                                                <tr>
                                                    <td><p><strong>Description:</strong></p></td>
                                                    <td><p>{selectedRow?.description?.description}</p></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="grey-btn" onClick={handleViewClose}>Close</Button>
                                    </DialogActions>
                                </Dialog>

                                {/* Edit Modal */}
                                <Dialog open={isEditOpen} onClose={handleEditClose} maxWidth={"md"} fullWidth={true}>
                                    <DialogTitle>Edit Banner</DialogTitle>
                                    <form onSubmit={handleEditSave}>
                                        <DialogContent>
                                            <img
                                                style={{ width: "300px", height: "240px" }}
                                                className="rounded"
                                                src={Image ? Image : appConstants.imageUrl + selectedRow?.imageUrl}
                                                alt="demo"
                                            />
                                            <div className="d-flex mt-2">
                                                <input
                                                    className="form-control foot-input "
                                                    onChange={handleFileChange}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/webp"
                                                />
                                                <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px",color:"grey" }}>(520×250)</span>
                                            </div>
                                            <TextField
                                                margin="dense"
                                                label="Title"
                                                type="text"
                                                fullWidth
                                                value={selectedRow?.description?.title || ''}
                                                onChange={(e) => setSelectedRow((prevRow) => ({
                                                    ...prevRow,
                                                    description: {
                                                        ...prevRow.description,
                                                        title: e.target.value
                                                    }
                                                }))}
                                            />
                                            <TextField
                                                margin="dense"
                                                label="Description"
                                                type="text"
                                                fullWidth
                                                value={selectedRow?.description?.description || ''}
                                                onChange={(e) => setSelectedRow((prevRow) => ({
                                                    ...prevRow,
                                                    description: {
                                                        ...prevRow.description,
                                                        description: e.target.value
                                                    }
                                                }))}
                                            />
                                            {/* Status Toggle */}
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={selectedRow?.status === 'active'}
                                                        onChange={(e) => setSelectedRow((prevRow) => ({
                                                            ...prevRow,
                                                            status: e.target.checked ? 'active' : 'inactive'
                                                        }))}
                                                        color="primary"
                                                    />
                                                }
                                                label="Active"
                                                labelPlacement="end"
                                                style={{ marginTop: '1rem' }}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button className="btn" type="submit" variant="contained">Save</Button>
                                            <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                                        </DialogActions>
                                    </form>
                                </Dialog>


                                {/* Add Modal */}
                                <Dialog open={isAddOpen} onClose={handleAddClose} maxWidth={"md"} fullWidth={true}>
                                    <DialogTitle>Add New Banner</DialogTitle>
                                    <form onSubmit={handleAddSave}>
                                        <DialogContent>
                                            {Image ?
                                                <img
                                                    style={{ width: "300px", height: "240px" }}
                                                    className="rounded"
                                                    src={Image}
                                                    alt="demo"
                                                /> : ''}
                                            <div className="d-flex mt-2">
                                                <input
                                                    className="form-control foot-input"
                                                    onChange={handleFileChange}
                                                    type="file"
                                                    accept="image/png, image/jpeg,image/webp"
                                                />
                                                <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px",color:"grey" }}>(520×250)</span>
                                            </div>
                                            <TextField
                                                margin="dense"
                                                label="Title"
                                                type="text"
                                                fullWidth
                                                value={selectedRow?.description?.title || ''}
                                                onChange={(e) => setSelectedRow((prevRow) => ({
                                                    ...prevRow,
                                                    description: {
                                                        ...prevRow.description,
                                                        title: e.target.value
                                                    }
                                                }))}
                                            />
                                            <TextField
                                                margin="dense"
                                                label="Description"
                                                type="text"
                                                fullWidth
                                                value={selectedRow?.description?.description || ''}
                                                onChange={(e) => setSelectedRow((prevRow) => ({
                                                    ...prevRow,
                                                    description: {
                                                        ...prevRow.description,
                                                        description: e.target.value
                                                    }
                                                }))}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button className="btn" type="submit" variant="contained">Save</Button>
                                            <Button variant="contained" className="grey-btn" onClick={handleAddClose}>Cancel</Button>
                                        </DialogActions>
                                    </form>
                                </Dialog>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{background:"transparent"}}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default MyWorldBanner;