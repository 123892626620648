import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './components/notFound';
import Dashboard from './pages/dashboard';
import Login from './pages/Login';
import Article from './pages/article';
import Banners from './pages/banner';
import ProtectedRoute from './components/protectedRoute';
import AddArticle from './pages/newArticle';
import ChangePassword from './pages/changePassword';
import Home from './components/cms/home/homepage';
import FaqSection from './components/Faq';
import { pageRoutes } from './config/route';
import Header from './components/cms/header';
import Footer from './components/cms/footer';
import MyWorldBanner from './pages/myworldbanner';
import SponserChildPage from './components/cms/sponserchild/sponserchild';
import HowtoWorkPage from './components/cms/about/howtowork';
import CampaignPage from './components/cms/campaign';
import StoryofChange from './components/cms/about/storyofchange';
import PartnerSpeak from './components/cms/about/partnerspeak';
import Leadership from './components/cms/about/leadership';
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={pageRoutes.login} element={<Login />} />
        {/* Protected Route Pages */}
        <Route path={pageRoutes.dashboard} element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />

        {/* banner */}
        {/* <Route path={pageRoutes.banners} element={<ProtectedRoute ><Banners /></ProtectedRoute>} /> */}
        <Route path={pageRoutes.banners} element={<ProtectedRoute ><Banners /></ProtectedRoute>} />

        {/* article */}
        <Route path={pageRoutes.article} element={<ProtectedRoute ><Article /></ProtectedRoute>} />
        <Route path={pageRoutes.addArticle} element={<ProtectedRoute ><AddArticle /></ProtectedRoute>} />

        {/* faq */}
        <Route path={pageRoutes.FAQ} element={<ProtectedRoute ><FaqSection /></ProtectedRoute>} />


        {/* Cms pages */}
        <Route path={pageRoutes.cmsHome} element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path={pageRoutes.header} element={<ProtectedRoute><Header /></ProtectedRoute>} />
        <Route path={pageRoutes.footer} element={<ProtectedRoute><Footer /></ProtectedRoute>} />
        <Route path={pageRoutes.campaign} element={<ProtectedRoute><CampaignPage /></ProtectedRoute>} />

        {/* my world banner */}
        <Route path={pageRoutes.myworld} element={<ProtectedRoute ><MyWorldBanner /></ProtectedRoute>} />
        {/* Sponser-child */}
        <Route path={pageRoutes.sponserChild} element={<ProtectedRoute ><SponserChildPage /></ProtectedRoute>} />
        {/* about-us */}
        <Route path={pageRoutes.howtowork} element={<ProtectedRoute ><HowtoWorkPage /></ProtectedRoute>} />
        <Route path={pageRoutes.storyofchange} element={<ProtectedRoute ><StoryofChange /></ProtectedRoute>} />
        <Route path={pageRoutes.partnerspeak} element={<ProtectedRoute ><PartnerSpeak /></ProtectedRoute>} />
        <Route path={pageRoutes.leadership} element={<ProtectedRoute ><Leadership /></ProtectedRoute>} />       


        {/* NotFound page */}
        <Route path='*' element={<NotFound />} />
        {/* ForgotPassword */}
        <Route exact path={pageRoutes.passwordChange} element={<ChangePassword />} />
      </Routes>
    </Router>
  );
}

export default App;