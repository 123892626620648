import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import apiFunctions from "../apiKit/api";
import Swal from "sweetalert2";
import Loader from "../components/Loader";

const Banners = () => {
    const [bannerImages, setBannerImages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fetchBanners = useCallback(async () => {
        apiFunctions.bannerApi().then((res) => {
            if (res.status === 200) {
                console.log("success", res);
                setBannerImages(res.data);
            } else {
                console.log("Failed to fetch banners");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        fetchBanners();
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [fetchBanners]);

    const getActiveCount = () => {
        return bannerImages.filter(item => item.status === "active").length;
    };

    const handleCheck = (index) => {
        const updatedImages = [...bannerImages];
        const item = updatedImages[index];

        if (getActiveCount() >= 5 && item.status !== "active") {
            Swal.fire({
                icon: "warning",
                text: "There are already 5 active items. You cannot select more."
            });
            return;
        }

        item.status = item.status === "active" ? "inactive" : "active";
        setBannerImages(updatedImages);
    };

    const handleLabelChange = (index, newLabel) => {
        const updatedImages = [...bannerImages];
        updatedImages[index].label = newLabel;
        setBannerImages(updatedImages);
    };

    const handleDescriptionChange = (index, description) => {
        const updatedImages = [...bannerImages];
        updatedImages[index].description = description;
        setBannerImages(updatedImages);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const changedItems = bannerImages.map(item => ({
            id: item.id,
            status: item.status,
            label: item.label
        }));
        console.log("Changed Items:", changedItems);

        apiFunctions.bannerUpdateApi(changedItems).then((res) => {
            if (res.status === 200) {
                console.log("success");
                fetchBanners();
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    };

    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <div className="row">
                    {/* <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div> */}
                    <div className={"col-12 w-100 dashboard_card main_layout"}>
                        {/* <div className="d-flex w-100 justify">
                            <IconButton className="web-btn" onClick={() => setIsopen(!isOpen)}>
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <Logout />
                            </div>
                        </div> */}
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row main">
                                <div className="row sub-menu">
                                    <div className="col-12">
                                        <Card>
                                            <CardContent>
                                                <div className="text-start"><h4 className="fw-bold">Banners</h4></div>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="mt-3">
                                                        <div className="row">
                                                            {bannerImages.map((item, index) => {
                                                                // const isDisabled = getActiveCount() >= 5 && item.status !== "active";
                                                                const isLabelDisabled = item.status === "inactive"; // Disable label input if status is inactive
                                                                return (
                                                                    <div className="col-lg-4 col-md-2 col-sm-3 col-4" key={item.id}>
                                                                        <div className="image-checkbox-wrapper">
                                                                            <img
                                                                                src={item.imageUrl}
                                                                                alt="demo"
                                                                                className="img-fluid rounded bann-img"
                                                                            />
                                                                            <div className="form-check mt-2">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id={`checkbox-${index}`}
                                                                                    checked={item.status === "active"}
                                                                                    onChange={() => handleCheck(index)}
                                                                                    // disabled={isDisabled}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                />
                                                                                <textarea
                                                                                    value={item.label}
                                                                                    placeholder="Title"
                                                                                    onChange={(e) => handleLabelChange(index, e.target.value)}
                                                                                    className="form-control mb-1"
                                                                                    disabled={isLabelDisabled} // Disable input if item is inactive
                                                                                />
                                                                                <textarea
                                                                                    value={item.description}
                                                                                    placeholder="Description"
                                                                                    rows={4}
                                                                                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                                                                    className="form-control mb-3"
                                                                                    disabled={isLabelDisabled}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="text-end">
                                                        <Button className="mt-3 btn" type="submit" variant="contained">Save</Button>
                                                    </div>
                                                </form>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{background:"transparent"}}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
        </>
    );
};

export default Banners;
