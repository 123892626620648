import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../../src/custom.css";
import Logout from "../../Logout";
import Loader from "../../Loader";
import Dropdown from "../../Dropdown";
import apiFunctions from "../../../apiKit/api";
import ReduxComponent from "../../../components/reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
import SponserHowWeWorksection from "./howeworksection";
const SponserChildPage = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { InvloveSponserdata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }


    // variables
    //why sponser-child
    const [Logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const [Title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [empTitle, setEmpTitle] = useState("");
    const [empDes, setEmpDes] = useState("");
    const [provideTitle, setProvideTitle] = useState("");
    const [provideDes, setProvideDes] = useState("");
    const [connectTitle, setConnectTitle] = useState("");
    const [connectDes, setConnectDes] = useState("");
    const [ImpTitle, setImpTitle] = useState("");
    const [ImpDes, setImpDes] = useState("");
    //1000-month
    const [donTitle, setDonTitle] = useState("");
    const [donDes, setDonDes] = useState("");
    const [accessTitle, setAccessTitle] = useState("");
    const [accessDes, setAccessDes] = useState("");
    const [healthTitle, setHealthTitle] = useState("");
    const [healthDes, setHealthDes] = useState("");
    const [nutriTitle, setNutriTitle] = useState("");
    const [nutriDes, setNutriDes] = useState("");
    const [safeTitle, setSafeTitle] = useState("");
    const [safeDes, setSafeDes] = useState("");
    //support-make
    const [supportTitle, setSupportTitle] = useState("");
    const [supportSubTitle, setSupportSubTitle] = useState("");
    const [supportImg1, setSupportImg1] = useState(null);
    const [supportImg1file, setSupportImg1file] = useState(null);
    const [Img1Title, setImg1Title] = useState("");
    const [Img1Des, setImg1Des] = useState("");
    const [supportImg2, setSupportImg2] = useState(null);
    const [supportImg2file, setSupportImg2file] = useState(null);
    const [Img2Title, setImg2Title] = useState("");
    const [Img2Des, setImg2Des] = useState("");
    const [supportImg3, setSupportImg3] = useState(null);
    const [supportImg3file, setSupportImg3file] = useState(null);
    const [Img3Title, setImg3Title] = useState("");
    const [Img3Des, setImg3Des] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(InvloveSponserdata, "InvloveSponserdata");
        setData(InvloveSponserdata);
    }, [InvloveSponserdata])

    //set-Data
    const setData = (data) => {
        const sponser = JSON.parse(data?.[0]?.content);
        const cando = JSON.parse(data?.[1]?.content);
        const support1 = JSON.parse(data?.[3]?.content);
        const support2 = JSON.parse(data?.[4]?.content);
        const support3 = JSON.parse(data?.[5]?.content);


        //Why Sponser A Child section
        setLogo(appConstants.imageUrl + data[0].images);
        setTitle(sponser?.title);
        setDescription(sponser?.description);
        setEmpTitle(sponser?.empTitle);
        setEmpDes(sponser?.empDes);
        setProvideTitle(sponser?.provideTitle);
        setProvideDes(sponser?.provideDes);
        setConnectTitle(sponser?.connectTitle);
        setConnectDes(sponser?.connectDes);
        setImpTitle(sponser?.ImpTitle);
        setImpDes(sponser?.ImpDes);
        // 1000-Month Donate section
        setDonTitle(cando?.donTitle);
        setDonDes(cando?.donDes);
        setAccessTitle(cando?.accessTitle);
        setHealthTitle(cando?.healthTitle);
        setNutriTitle(cando?.nutriTitle);
        setSafeTitle(cando?.safeTitle);
        //support-1
        setSupportImg1(appConstants.imageUrl + data?.[3]?.images);
        setSupportTitle(support1?.supportTitle);
        setSupportSubTitle(support1?.supportSubTitle);
        setImg1Title(support1?.title);
        setImg1Des(support1?.description);
        //support-2
        setSupportImg2(appConstants.imageUrl + data?.[4]?.images);    
        setImg2Title(support2?.title);
        setImg2Des(support2?.description);
        //support-3
        setSupportImg3(appConstants.imageUrl + data?.[5]?.images);    
        setImg3Title(support3?.title);
        setImg3Des(support3?.description);
    }

    //why-sponser-submit
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": Title,
            "description": description,
            "empTitle": empTitle,
            "empDes": empDes,
            "provideTitle": provideTitle,
            "provideDes": provideDes,
            "connectTitle": connectTitle,
            "connectDes": connectDes,
            "ImpTitle": ImpTitle,
            "ImpDes": ImpDes
        }
        if (LogoFile) {
            form.append("image", LogoFile);
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsInvolveWhysponser(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }
    //1000-month submit
    const handleDonationFormSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "donTitle": donTitle,
            "donDes": donDes,
            "accessTitle": accessTitle,
            "accessDes": accessDes,
            "healthTitle": healthTitle,
            "healthDes": healthDes,
            "nutriTitle": nutriTitle,
            "nutriDes": nutriDes,
            "safeTitle": safeTitle,
            "safeDes": safeDes
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsInvolveWhatcan(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    //Support make submit
    const handleSupportmakeSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var content1 = {
            "supportTitle": supportTitle,
            "supportSubTitle": supportSubTitle,
            "title": Img1Title,
            "description": Img1Des
        }
        if (supportImg1file) {
            form.append("image1", supportImg1file);
        }
        var content2 = {
            "title": Img2Title,
            "description": Img2Des
        }
        if (supportImg2file) {
            form.append("image2", supportImg2file);
        }
        var content3 = {
            "title": Img3Title,
            "description": Img3Des
        }
        if (supportImg3file) {
            form.append("image3", supportImg3file);
        }
        form.append("content1", JSON.stringify(content1));
        form.append("content2", JSON.stringify(content2));
        form.append("content3", JSON.stringify(content3));

        apiFunctions.addCmsInvolveSupportmake(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }




    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className="row">
                                    <div className="col-12 w-100">
                                        <Card className="p-2">
                                            <CardContent>
                                                <div className="text-start"><h5 className="fw-bold">Sponser A Child</h5></div>
                                                {/* Why sponser child */}
                                                <form onSubmit={handleFormSubmit}>
                                                    <h6 className="fw-bold mt-3">Why Sponser A Child section</h6>
                                                    <div>
                                                        {Logo ?
                                                            <div style={{ width: "150px" }}>
                                                                <label>Logo</label><br />
                                                                <img className="input-img" src={Logo} alt="demo" />
                                                            </div> : ''}
                                                        <div className="d-flex mt-3 multi-img">
                                                            <input
                                                                className="form-control foot-input"
                                                                onChange={(e) => {
                                                                    const file = e.target.files[0];
                                                                    if (file) {
                                                                        const objectUrl = URL.createObjectURL(file);
                                                                        setLogoFile(file);
                                                                        setLogo(objectUrl);
                                                                    }
                                                                }}
                                                                type="file"
                                                                accept="image/png, image/jpeg,image/webp"
                                                            />
                                                            <span className="mt-3 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Title</label>
                                                            <input className="form-control" value={Title} onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Why Sponser a Child" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Description</label>
                                                            <textarea rows={3} className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} type="text" placeholder="Sponser a Child" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Empower</b></label>
                                                            <input value={empTitle} onChange={(e) => setEmpTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                        <div className="col-lg-8 col-12">
                                                            <label>description</label>
                                                            <textarea value={empDes} onChange={(e) => setEmpDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                        </div>
                                                    </div>

                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Provide</b></label>
                                                            <input value={provideTitle} onChange={(e) => setProvideTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                        <div className="col-lg-8 col-12">
                                                            <label>description</label>
                                                            <textarea value={provideDes} onChange={(e) => setProvideDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Personal Connections</b></label>
                                                            <input value={connectTitle} onChange={(e) => setConnectTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                        <div className="col-lg-8 col-12">
                                                            <label>description</label>
                                                            <textarea value={connectDes} onChange={(e) => setConnectDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Lasting Impact</b></label>
                                                            <input value={ImpTitle} onChange={(e) => setImpTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                        <div className="col-lg-8 col-12">
                                                            <label>description</label>
                                                            <textarea value={ImpDes} onChange={(e) => setImpDes(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>
                                                {/* 1000 month */}
                                                <form onSubmit={handleDonationFormSubmit}>
                                                    <h6 className="fw-bold mt-3">1000-Month Donate section</h6>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Title</label>
                                                            <input className="form-control" value={donTitle} onChange={(e) => setDonTitle(e.target.value)} type="text" placeholder="Why Sponser a Child" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Description</label>
                                                            <textarea rows={3} className="form-control" value={donDes} onChange={(e) => setDonDes(e.target.value)} type="text" placeholder="Sponser a Child" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Access</b></label>
                                                            <textarea rows={2} value={accessTitle} onChange={(e) => setAccessTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Improved Health</b></label>
                                                            <textarea rows={2} value={healthTitle} onChange={(e) => setHealthTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Nutritious</b></label>
                                                            <textarea rows={2} value={nutriTitle} onChange={(e) => setNutriTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-8 col-12">
                                                            <label><b>Safe living</b></label>
                                                            <textarea rows={2} value={safeTitle} onChange={(e) => setSafeTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>
                                                {/* How we work section */}
                                                <div className="row mt-3">
                                                    <div className="col-12">
                                                        <h6 className="fw-bold mt-3">How We work section</h6>
                                                        <SponserHowWeWorksection />
                                                    </div>
                                                </div>
                                                {/* Support Make section */}
                                                <form onSubmit={handleSupportmakeSubmit}>
                                                    <h6 className="fw-bold mt-3">Support Make section</h6>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Title</label>
                                                            <input className="form-control" value={supportTitle} onChange={(e) => setSupportTitle(e.target.value)} type="text" placeholder="Title" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-8 col-12">
                                                            <label>Sub Title</label>
                                                            <input className="form-control" value={supportSubTitle} onChange={(e) => setSupportSubTitle(e.target.value)} type="text" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-4 col-12">
                                                            {supportImg1 ?
                                                                <div style={{ width: "150px" }}>
                                                                    <label>Image-1</label><br />
                                                                    <img className="input-img" src={supportImg1} alt="demo" />
                                                                </div> : ''}
                                                            <div className="d-flex mt-3 multi-img">
                                                                <input
                                                                    className="form-control foot-input"
                                                                    onChange={(e) => {
                                                                        const file = e.target.files[0];
                                                                        if (file) {
                                                                            const objectUrl = URL.createObjectURL(file);
                                                                            setSupportImg1file(file);
                                                                            setSupportImg1(objectUrl);
                                                                        }
                                                                    }}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg,image/webp"
                                                                />
                                                                <span className="mt-3 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                                            </div>
                                                            <label><b>Title</b></label>
                                                            <input className="form-control" value={Img1Title} onChange={(e) => setImg1Title(e.target.value)} type="text" placeholder="Why Sponser a Child" />
                                                            <textarea rows={2} value={Img1Des} onChange={(e) => setImg1Des(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                        </div>
                                                        <div className="col-lg-4 col-12">
                                                            {supportImg2 ?
                                                                <div style={{ width: "150px" }}>
                                                                    <label>Image-2</label><br />
                                                                    <img className="input-img" src={supportImg2} alt="demo" />
                                                                </div> : ''}
                                                            <div className="d-flex mt-3 multi-img">
                                                                <input
                                                                    className="form-control foot-input"
                                                                    onChange={(e) => {
                                                                        const file = e.target.files[0];
                                                                        if (file) {
                                                                            const objectUrl = URL.createObjectURL(file);
                                                                            setSupportImg2file(file);
                                                                            setSupportImg2(objectUrl);
                                                                        }
                                                                    }}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg,image/webp"
                                                                />
                                                                <span className="mt-3 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                                            </div>
                                                            <label><b>Title</b></label>
                                                            <input className="form-control" value={Img2Title} onChange={(e) => setImg2Title(e.target.value)} type="text" placeholder="Title" />
                                                            <textarea rows={2} value={Img2Des} onChange={(e) => setImg2Des(e.target.value)} className="form-control" type="text" placeholder="Description" />
                                                        </div>
                                                        <div className="col-lg-4 col-12">
                                                            {supportImg3 ?
                                                                <div style={{ width: "150px" }}>
                                                                    <label>Image-3</label><br />
                                                                    <img className="input-img" src={supportImg3} alt="demo" />
                                                                </div> : ''}
                                                            <div className="d-flex mt-3 multi-img">
                                                                <input
                                                                    className="form-control foot-input"
                                                                    onChange={(e) => {
                                                                        const file = e.target.files[0];
                                                                        if (file) {
                                                                            const objectUrl = URL.createObjectURL(file);
                                                                            setSupportImg3file(file);
                                                                            setSupportImg3(objectUrl);
                                                                        }
                                                                    }}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg,image/webp"
                                                                />
                                                                <span className="mt-3 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                                            </div>
                                                            <label><b>Title</b></label>
                                                            <input className="form-control" value={Img3Title} onChange={(e) => setImg3Title(e.target.value)} type="text" placeholder="Title" />
                                                            <textarea rows={2} value={Img3Des} onChange={(e) => setImg3Des(e.target.value)} className="form-control" type="text" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>
                                            </CardContent>
                                        </Card>
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />


        </>
    )
}
export default SponserChildPage;