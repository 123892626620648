import React, { useCallback, useEffect, useState } from "react";
import SideBar from "../components/Sidebar";
import { Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../src/custom.css";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import Logout from "../components/Logout";
import Loader from "../components/Loader";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Link } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import { pageRoutes } from "../config/route";
import apiFunctions from "../apiKit/api";
import ReduxComponent from "./reduxComponent";

const Dashboard = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [bannersCount, setBannersCount] = useState(0);
    const [faqCount, setFaqCount] = useState(0);


    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const fetchDashCounts = useCallback(async () => {
        apiFunctions.getDashboardApi().then((res) => {
            if (res.status === 200) {
                setBannersCount(res.data.bannerCount);
                setFaqCount(res.data.faqCount);
            } else {
                console.log("Failed to fetch banners");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        fetchDashCounts();
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [fetchDashCounts])
    return (
        <>


            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} dashboard_card main_layout`} >

                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className=" mt-3 main">
                                <div className="p-5 row sub-menu">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            {/* <Link to={pageRoutes.banners} style={{ textDecoration: "none", color: "white" }} > */}
                                            <CardContent>
                                                <h5 className="dash_count_text">Banners</h5>
                                                <div className="d-flex justify text-white">
                                                    <PermMediaIcon color="white" />
                                                    <h3 className="dash_count_text">10</h3>
                                                </div>
                                            </CardContent>
                                            {/* </Link> */}
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            {/* <Link to={pageRoutes.FAQ} style={{ textDecoration: "none", color: "white" }} > */}
                                            <CardContent>
                                                <h5 className="dash_count_text">FAQ</h5>
                                                <div className="d-flex justify text-white">
                                                    <AnalyticsIcon color="white" />
                                                    <h3 className="dash_count_text">05</h3>
                                                </div>
                                            </CardContent>
                                            {/* </Link> */}
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <Card elevation={5} className="dash_count mb-3">
                                            <CardContent>
                                                <h5 className="dash_count_text">Cms Pages</h5>
                                                <div className="d-flex justify text-white">
                                                    <AnalyticsIcon />
                                                    <h3 className="dash_count_text">10</h3>
                                                </div>
                                            </CardContent>

                                        </Card>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{background:"transparent"}}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Dashboard;