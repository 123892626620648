export const appConstants = {
    baseUrl: "https://api.worldvisionindia.dci.in/v1",
    imageUrl: "https://api.worldvisionindia.dci.in/",
    appName: "Talor",
    login: "Admin Login",
    emailAddress: "Email Address",
    logoutWarning: "Are you sure, you want to logout?",
    logout: "Log out",
    yes: "Yes",
    no: "No",
    email: "Email",
    dashboard: "Dashboard",
    deleteConfirm: "Are sure want to delete?",
    deleteSuccess: "Deleted successfully?",
    deleteConfirmfaq:"Are sure want to delete this FAQ?"


}

export const apiConstants = {

    // Search bar
    handleSearchOnChange: "handleSearchOnChange",

    // authentication
    apiLoginInitiate: "apiLoginInitiate",
    apiLoginSuccess: "apiLoginSuccess",


}