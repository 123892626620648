import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiFunctions from '../../apiKit/api';

// Async Thunk to call multiple APIs, including custom API functions
export const fetchAPIs = createAsyncThunk('api/fetchAPIs', async () => {
  const [headerResponse] = await Promise.all([
    apiFunctions.getCmsHeaderapi(),
  ]);
  const [footerResponse] = await Promise.all([
    apiFunctions.getCmsFooterapi(),
  ]);
  const [cmsBannerResponse] = await Promise.all([
    apiFunctions.getCmsHomeBannerapi(),
  ]);
  const [myworldBannerResponse] = await Promise.all([
    apiFunctions.getMyworldBannerapi(),
  ]);
  const [myworldBannerContentResponse] = await Promise.all([
    apiFunctions.getCmsMyworldcontentapi(),
  ]);
  const [cmsHomeChildResponse] = await Promise.all([
    apiFunctions.getCmsHomeChildSponserapi(),
  ]);
  const [cmsHomeJourneyResponse] = await Promise.all([
    apiFunctions.getCmsHomeJourneyapi(),
  ]);
  const [cmsHomeFocusAreaResponse] = await Promise.all([
    apiFunctions.getCmsHomeFocusAreaapi(),
  ]);
  const [cmsHomeImpactResponse] = await Promise.all([
    apiFunctions.getCmsHomeImpactapi(),
  ]);
  const [cmsHomePresenceResponse] = await Promise.all([
    apiFunctions.getCmsHomePresenceapi(),
  ]);
  const [cmsHomeTwobannerResponse] = await Promise.all([
    apiFunctions.getCmsHomeTwobannerapi(),
  ]);
  const [cmsHomeCSRResponse] = await Promise.all([
    apiFunctions.getCmsHomeCSRdata(),
  ]);
  const [cmsSponserHowResponse] = await Promise.all([
    apiFunctions.getCmsSponserHowweworkdata(),
  ]);
  const [cmsHomeNewsResponse] = await Promise.all([
    apiFunctions.getCmsHomeInNewsdata(),
  ]);
  const [cmsHomeAwardResponse] = await Promise.all([
    apiFunctions.getCmsHomeAwarddata(),
  ]);

  const [cmsHomeCampaignResponse] = await Promise.all([
    apiFunctions.getCmsHomeCampaignapi(),
  ]);
  const [cmsHomeAccrediationResponse] = await Promise.all([
    apiFunctions.getCmsHomeAccrediationdata(),
  ]);
  const [cmsHomeFAQResponse] = await Promise.all([
    apiFunctions.getCmsHomeFAQContentapi(),
  ]);
  const [cmsHomeTestResponse] = await Promise.all([
    apiFunctions.getCmsHomeTestimonialdata(),
  ]);
  const [cmsHomeStaffResponse] = await Promise.all([
    apiFunctions.getCmsHomeStaffdata(),
  ]);
  const [cmsHomeSponserResponse] = await Promise.all([
    apiFunctions.getCmsHomeSponserdata(),
  ]);
  const [cmsAboutHowResponse] = await Promise.all([
    apiFunctions.getCmsAboutHowtoworkdata(),
  ]);
  const [cmsAboutStoryResponse] = await Promise.all([
    apiFunctions.getCmsStoryofchangedata(),
  ]);
  const [cmsAboutPartnerSpeakResponse] = await Promise.all([
    apiFunctions.getCmsPartnerspeakdata(),
  ]);
  const [cmsAboutPartnersResponse] = await Promise.all([
    apiFunctions.getCmsPartnersdata(),
  ]);
  const [cmsAboutBoardMemberResponse] = await Promise.all([
    apiFunctions.getCmsBoardmemberdata(),
  ]);
  const [cmsAboutBoardMissionResponse] = await Promise.all([
    apiFunctions.getCmsBoardMissiondata(),
  ]);
  const [cmsAboutLeaderResponse] = await Promise.all([
    apiFunctions.getCmsLeadershipContent(),
  ]);
  const [cmsSponserChildResponse] = await Promise.all([
    apiFunctions.getCmsInvolveSponserdata(),
  ]);


  let headerdata = [];
  if (headerResponse.status === 200) {
    headerdata = headerResponse?.data?.details;
  }
  let footerdata = [];
  if (footerResponse.status === 200) {
    footerdata = footerResponse?.data?.details;
  }
  let bannerdata = [];
  if (cmsBannerResponse.status === 200) {
    bannerdata = cmsBannerResponse?.data;
  }
  let worldbannerdata = [];
  if (myworldBannerResponse.status === 200) {
    worldbannerdata = myworldBannerResponse?.data;
  }
  let homeChilddata = [];
  if (cmsHomeChildResponse.status === 200) {
    homeChilddata = cmsHomeChildResponse?.data?.details;
  }
  let worldContentdata = [];
  if (myworldBannerContentResponse.status === 200) {
    worldContentdata = myworldBannerContentResponse?.data?.details;
  }
  let homeJourneydata = [];
  if (cmsHomeJourneyResponse.status === 200) {
    homeJourneydata = cmsHomeJourneyResponse?.data?.details;
  }
  let focusAreadata = [];
  if (cmsHomeFocusAreaResponse.status === 200) {
    focusAreadata = cmsHomeFocusAreaResponse?.data;
  }
  let Impactdata = [];
  if (cmsHomeImpactResponse.status === 200) {
    Impactdata = cmsHomeImpactResponse?.data?.details;
  }
  let presencedata = [];
  if (cmsHomePresenceResponse.status === 200) {
    presencedata = cmsHomePresenceResponse?.data?.details;
  }
  let twobannerdata = [];
  if (cmsHomeTwobannerResponse.status === 200) {
    twobannerdata = cmsHomeTwobannerResponse?.data?.details;
  }
  let CSRdata = [];
  if (cmsHomeCSRResponse.status === 200) {
    CSRdata = cmsHomeCSRResponse?.data;
  }
  let SponsHowdata = [];
  if (cmsSponserHowResponse.status === 200) {
    SponsHowdata = cmsSponserHowResponse?.data;
  }
  let newsdata = [];
  if (cmsHomeNewsResponse.status === 200) {
    newsdata = cmsHomeNewsResponse?.data;
  }
  let awarddata = [];
  if (cmsHomeAwardResponse.status === 200) {
    awarddata = cmsHomeAwardResponse?.data;
  }

  let campaigndata = [];
  if (cmsHomeCampaignResponse.status === 200) {
    campaigndata = cmsHomeCampaignResponse?.data?.details;
  }
  let accrediation = [];
  if (cmsHomeAccrediationResponse.status === 200) {
    accrediation = cmsHomeAccrediationResponse?.data;
  }
  let faqdata = [];
  if (cmsHomeFAQResponse.status === 200) {
    faqdata = cmsHomeFAQResponse?.data;
  }
  let testdata = [];
  if (cmsHomeTestResponse.status === 200) {
    testdata = cmsHomeTestResponse?.data?.details;
  }
  let staffdata = [];
  if (cmsHomeStaffResponse.status === 200) {
    staffdata = cmsHomeStaffResponse?.data;
  }
  let sponserdata = [];
  if (cmsHomeSponserResponse.status === 200) {
    sponserdata = cmsHomeSponserResponse?.data;
  }
  let howtoworkdata = [];
  if (cmsAboutHowResponse.status === 200) {
    howtoworkdata = cmsAboutHowResponse?.data;
  }
  let InvloveSponserdata = [];
  if (cmsSponserChildResponse.status === 200) {
    InvloveSponserdata = cmsSponserChildResponse?.data;
  }

  let storydata = [];
  if (cmsAboutStoryResponse.status === 200) {
    storydata = cmsAboutStoryResponse?.data;
  }
  let partnerspeakdata = [];
  if (cmsAboutPartnerSpeakResponse.status === 200) {
    partnerspeakdata = cmsAboutPartnerSpeakResponse?.data;
  }
  let partnersdata = [];
  if (cmsAboutPartnersResponse.status === 200) {
    partnersdata = cmsAboutPartnersResponse?.data;
  }
  let membersdata = [];
  if (cmsAboutBoardMemberResponse.status === 200) {
    membersdata = cmsAboutBoardMemberResponse?.data;
  }
  let missiondata = [];
  if (cmsAboutBoardMissionResponse.status === 200) {
    missiondata = cmsAboutBoardMissionResponse?.data;
  }
  let leadershipdata = [];
  if (cmsAboutLeaderResponse.status === 200) {
    leadershipdata = cmsAboutLeaderResponse?.data;
  }
  return {
    headerdata, footerdata, bannerdata, homeChilddata, homeJourneydata, focusAreadata, Impactdata, presencedata,
    twobannerdata, CSRdata, newsdata, awarddata, campaigndata, accrediation, faqdata, staffdata, sponserdata, testdata,
    worldbannerdata, worldContentdata, howtoworkdata, SponsHowdata, InvloveSponserdata, storydata, partnerspeakdata,
    partnersdata, membersdata, missiondata,leadershipdata
  };
});

const ApiSlice = createSlice({
  name: 'wvadminapi',
  initialState: {
    headerdata: [],
    footerdata: [],
    bannerdata: [],
    cmsHomeChilddata: [],
    worldContentdata: [],
    journeydata: [],
    focusAreadata: [],
    Impactdata: [],
    presencedata: [],
    twobannerdata: [],
    csrdata: [],
    sponshowdata: [],
    newsdata: [],
    awarddata: [],
    campaigndata: [],
    accrediation: [],
    worldbannerdata: [],
    faqdata: [],
    testdata: [],
    staffdata: [],
    sponserdata: [],
    howtoworkdata: [],
    storydata: [],
    partnerspeakdata: [],
    partnersdata: [],
    membersdata: [],
    missiondata: [],
    leadershipdata:[],
    InvloveSponserdata: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAPIs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAPIs.fulfilled, (state, action) => {
        state.loading = false;
        state.headerdata = action.payload.headerdata;
        state.footerdata = action.payload.footerdata;
        state.bannerdata = action.payload.bannerdata;
        state.worldbannerdata = action.payload.worldbannerdata;
        state.cmsHomeChilddata = action.payload.homeChilddata;
        state.worldContentdata = action.payload.worldContentdata;
        state.journeydata = action.payload.homeJourneydata;
        state.focusAreadata = action.payload.focusAreadata;
        state.Impactdata = action.payload.Impactdata;
        state.presencedata = action.payload.presencedata;
        state.twobannerdata = action.payload.twobannerdata;
        state.csrdata = action.payload.CSRdata;
        state.sponshowdata = action.payload.SponsHowdata;
        state.newsdata = action.payload.newsdata;
        state.awarddata = action.payload.awarddata;
        state.campaigndata = action.payload.campaigndata;
        state.accrediation = action.payload.accrediation;
        state.faqdata = action.payload.faqdata;
        state.testdata = action.payload.testdata;
        state.staffdata = action.payload.staffdata;
        state.sponserdata = action.payload.sponserdata;
        state.howtoworkdata = action.payload.howtoworkdata;
        state.storydata = action.payload.storydata;
        state.partnerspeakdata = action.payload.partnerspeakdata;
        state.partnersdata = action.payload.partnersdata;
        state.membersdata = action.payload.membersdata;
        state.missiondata = action.payload.missiondata;
        state.leadershipdata = action.payload.leadershipdata;
        state.InvloveSponserdata = action.payload.InvloveSponserdata;
      })
      .addCase(fetchAPIs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default ApiSlice.reducer;
