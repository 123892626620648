import { Button, ButtonGroup, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from "sweetalert2";
import Table from "../../Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import apiFunctions from "../../../apiKit/api";
import { appConstants } from "../../../apiKit/appConstant";

const FocusAreaSection = () => {
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState({
        description: {
            title: '',
            description: ''
        },
        status: 'active',
    });
    const [filteredRows, setFilteredRows] = useState([]);
    const [banners, setBanners] = useState([]);
    const [Image, setImage] = useState(null);
    const [ImageFile, setImageFile] = useState(null);
    const [Title, setTitle] = useState("");
    const [Description, setDescription] = useState("");


    const { focusAreadata } = useSelector((state) => state.api);
    const dispatch = useDispatch();

    const setData = (data) => {
        setTitle(data?.title);
        setDescription(data?.description);
    }

    useEffect(() => {
        const parsedData = focusAreadata?.images?.map(item => {
            try {
                const parsedDescription = typeof item.description === 'string' ? JSON.parse(item.description) : item.description;
                return {
                    ...item,
                    description: parsedDescription
                };
            } catch (error) {
                console.error("Failed to parse description:", error);
                return item;
            }
        }) || [];
        setBanners(parsedData);
        setData(JSON.parse(focusAreadata?.contents?.content))
    }, [focusAreadata]);
    // Columns for the table
    const columns = [
        {
            field: 'imageUrl',
            headerName: 'Image',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) =>
                params.row && (
                    <img
                        src={appConstants.imageUrl + params.row.imageUrl}
                        alt={params.row.description?.title || 'Image'}
                        width={100}
                    />
                ) // Safely render the image if params.row exists
        },
        {
            field: 'description.title',
            headerName: 'Title',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.title : '-'
        },
        {
            field: 'description.description',
            headerName: 'Description',
            flex: 1,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => params.row ? params.row?.description?.description : '-'
        },
        {
            field: 'Action',
            flex: 1,
            headerName: 'Action',
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params) => (
                params.row && (
                    <ButtonGroup>
                        <IconButton onClick={() => handleEditOpen(params.row)}><EditIcon /></IconButton>
                        <IconButton onClick={() => handleViewOpen(params.row)}><VisibilityIcon /></IconButton>
                        <IconButton onClick={() => handleRemove(params.row)}><DeleteIcon /></IconButton>
                    </ButtonGroup>
                )
            ) // Safely render the action buttons if params.row exists
        },
    ];

    // Function to handle Delete
    const handleRemove = (row) => {
        Swal.fire({
            text: `Are you sure you want to delete?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {
                apiFunctions.deleteCmsHomeFocusAreaImage([row.id]).then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            text: res.message,
                            icon: 'success',
                        });
                        dispatch(fetchAPIs());
                    } else {
                        Swal.fire({
                            text: res.message,
                            icon: 'error',
                        });
                    }
                }).catch(() => {
                    Swal.fire({
                        text: 'An error occurred. Please try again later.',
                        icon: 'error',
                    });
                });
            }
        });
    };

    const handleViewOpen = (row) => {
        setSelectedRow(row);
        setIsViewOpen(true);
    };

    const handleViewClose = () => {
        setIsViewOpen(false);
        setSelectedRow(null);
    };

    const handleEditOpen = (row) => {
        setSelectedRow(row);
        setIsEditOpen(true);
    };

    const handleEditClose = () => {
        setIsEditOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleEditSave = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", selectedRow?.status);
        form.append("id", selectedRow?.id);
        form.append("description", JSON.stringify(json))
        apiFunctions.updateCmsHomeFocusAreaImage(form).then((res) => {
            if (res.status === 200) {
                handleEditClose()
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                handleEditClose();
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            handleEditClose()
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });

        // setIsEditOpen(false);
        // setSelectedRow(null);
        // Swal.fire('', 'Saved Successfully', 'success');
    };

    const handleAddOpen = () => {
        setSelectedRow({
            description: {
                title: '',
                description: ''
            },
            status: 'active',
        });
        setIsAddOpen(true);
    };

    const handleAddClose = () => {
        setIsAddOpen(false);
        setSelectedRow(null);
        setImage(null);
        setImageFile(null);
    };

    const handleAddSave = (e) => {
        e.preventDefault();
        setFilteredRows([...filteredRows, { ...selectedRow, id: banners.length + 1 }]);
        const form = new FormData();
        var json = {
            "title": selectedRow?.description?.title,
            "description": selectedRow?.description?.description,
            "buttonContent": "Donate Now",
        }
        if (ImageFile instanceof File) {
            form.append("image", ImageFile);
        }
        form.append("status", "active")
        form.append("description", JSON.stringify(json))
        apiFunctions.addCmsHomeFocusAreaapi(form).then((res) => {
            if (res.status === 200) {
                handleAddClose()
                Swal.fire({
                    text: res.message,
                    icon: 'success',
                });
                dispatch(fetchAPIs());
            } else {
                handleAddClose();
                Swal.fire({
                    text: res.message,
                    icon: 'error',
                });
            }
        }).catch(() => {
            handleAddClose()
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error',
            });
        });

    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(e.target.files[0]);
            const objectUrl = URL.createObjectURL(file);
            setImage(objectUrl);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "our_focus_areas",
            "content": {
                "title": Title,
                "description": Description,
            }
        }
        apiFunctions.updateCmsHomeFocusAreaContent(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-2">
                        <CardContent>
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <div>
                                                <label>Title</label>
                                                <input value={Title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Our Focus Ares" />
                                            </div>
                                            <div>
                                                <label>Description</label>
                                                <textarea value={Description} onChange={(e) => setDescription(e.target.value)} rows={4} type="text" className="form-control" placeholder="Our Focus Ares" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-end mb-3">
                                        <Button className="mt-3 btn" type="submit" variant="contained">Save</Button>
                                    </div>
                                </form>
                            </div>
                            <div className="text-end">
                                <Button className="btn mb-2" onClick={handleAddOpen} endIcon={<AddIcon />} variant="contained">Add new</Button>
                            </div>
                            <Table rows={banners} columns={columns} />

                        </CardContent>
                    </Card>
                </div>
            </div>

            {/* View Modal */}
            <Dialog open={isViewOpen} onClose={handleViewClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>View Focus Area</DialogTitle>
                <DialogContent>
                    <img src={appConstants.imageUrl + selectedRow?.imageUrl} alt={selectedRow?.description?.title} style={{ width: "300px", height: "240px", marginTop: '10px' }} />
                    <table className="table table-bordered mt-2">
                        <tbody>
                            <tr>
                                <td><p><strong>Title:</strong></p></td>
                                <td><p>{selectedRow?.description?.title}</p></td>
                            </tr>
                            <tr>
                                <td><p><strong>Description:</strong></p></td>
                                <td><p>{selectedRow?.description?.description}</p></td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button className="grey-btn" onClick={handleViewClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Edit Modal */}
            <Dialog open={isEditOpen} onClose={handleEditClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Edit Focus Area</DialogTitle>
                <form onSubmit={handleEditSave}>
                    <DialogContent>
                        <img
                            style={{ width: "300px", height: "240px" }}
                            className="rounded"
                            src={Image ? Image : appConstants.imageUrl + selectedRow?.imageUrl}
                            alt="demo"
                        />
                        <div className="d-flex mt-2">
                            <input
                                className="form-control foot-input"
                                onChange={handleFileChange}
                                type="file"
                                accept="image/png, image/jpeg,image/webp"
                            />
                            <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                        </div>
                        <TextField
                            margin="dense"
                            label="Title"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.title || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    title: e.target.value
                                }
                            }))}
                        />
                        <TextField
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.description || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    description: e.target.value
                                }
                            }))}
                        />
                        {/* Status Toggle */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectedRow?.status === 'active'}
                                    onChange={(e) => setSelectedRow((prevRow) => ({
                                        ...prevRow,
                                        status: e.target.checked ? 'active' : 'inactive'
                                    }))}
                                    color="primary"
                                />
                            }
                            label="Active"
                            labelPlacement="end"
                            style={{ marginTop: '1rem' }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn" type="submit" variant="contained">Save</Button>
                        <Button variant="contained" className="grey-btn" onClick={handleEditClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>


            {/* Add Modal */}
            <Dialog open={isAddOpen} onClose={handleAddClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>Add New Focus Area</DialogTitle>
                <form onSubmit={handleAddSave}>
                    <DialogContent>
                        {Image ?
                            <img
                                style={{ width: "300px", height: "240px" }}
                                className="rounded"
                                src={Image}
                                alt="demo"
                            /> : ""}
                        <div className="d-flex mt-2">
                            <input
                                className="form-control foot-input"
                                onChange={handleFileChange}
                                type="file"
                                accept="image/png, image/jpeg"
                            />
                            <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                        </div>
                        <TextField
                            margin="dense"
                            label="Title"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.title || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    title: e.target.value
                                }
                            }))}
                        />
                        <TextField
                            margin="dense"
                            label="Description"
                            type="text"
                            fullWidth
                            value={selectedRow?.description?.description || ''}
                            onChange={(e) => setSelectedRow((prevRow) => ({
                                ...prevRow,
                                description: {
                                    ...prevRow.description,
                                    description: e.target.value
                                }
                            }))}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button className="btn" type="submit" variant="contained">Save</Button>
                        <Button variant="contained" className="grey-btn" onClick={handleAddClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
export default FocusAreaSection;