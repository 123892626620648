import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiFunctions from "../../../apiKit/api";
import Swal from "sweetalert2";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
const JourneySection = () => {
    const [Logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const dispatch = useDispatch();
    const { journeydata } = useSelector((state) => state.api);

    // variables
    const [transformTitle, setTransformTitle] = useState("");
    const [transformsubTitle, setTransformsubTitle] = useState("");
    const [transformDes, setTransformDes] = useState("");
    const [learnText, setLearnText] = useState("");
    const [learnLink, setLearnLink] = useState("");

    const setData = async (data, image) => {
        setLogo(appConstants.imageUrl + image)
        setTransformTitle(data?.title);
        setTransformsubTitle(data?.orangetxt);
        setTransformDes(data?.description);
        setLearnText(data?.learntext);
        setLearnLink(data?.learnlink);
    }

    useEffect(() => {
        setData(JSON.parse(journeydata[0]?.content), journeydata[0]?.images);
    }, [journeydata])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        // formData.append("fieldName", "wvi_journey");
        var json = {
            "title": transformTitle,
            "orangetxt": transformsubTitle,
            "description": transformDes,
            "learntext": learnText,
            "learnlink": learnLink
        }
        formData.append("contents", JSON.stringify(json));
        if (LogoFile instanceof File) {
            formData.append("image", LogoFile);
        }
        apiFunctions.updateCmsHomeJourneyapi(formData).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }
    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-lg-2 p-0">
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <h5><b>Logo and Title</b></h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Logo</label>
                                            <div>
                                                {Logo ?
                                                    <div style={{ width: "150px" }}>
                                                        <img className="input-img" src={Logo} alt="demo" />
                                                    </div> : ''}
                                                <div className="mt-2 multi-img d-flex">
                                                    <input
                                                        className="form-control foot-input"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file) {
                                                                const objectUrl = URL.createObjectURL(file);
                                                                setLogoFile(file);
                                                                setLogo(objectUrl);
                                                            }
                                                        }}
                                                        type="file"
                                                        accept="image/png, image/jpeg"
                                                    />
                                                    <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label>Transforming Lives</label>
                                            <div className="row">
                                                <div className="col-lg-6 col-12">
                                                    <input value={transformTitle} onChange={(e) => setTransformTitle(e.target.value)} type="text" className="form-control" placeholder="Transforming Lives" />
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <input value={transformsubTitle} onChange={(e) => setTransformsubTitle(e.target.value)} type="text" className="form-control" placeholder=" Seven Decades" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h5><b>Description</b></h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <textarea value={transformDes} onChange={(e) => setTransformDes(e.target.value)} rows={4} className="form-control" placeholder="description" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="row multi-input">
                                        <div className="d-flex">
                                            <div >
                                                <h6 style={{ textWrap: "nowrap" }} className="mt-2">Learn more:</h6>
                                            </div>
                                            <input type="text" value={learnText} onChange={(e) => setLearnText(e.target.value)} className="form-control ms-2 foot-input" id="Learn more" placeholder="Learn more" />
                                            <input type="text" value={learnLink} onChange={(e) => setLearnLink(e.target.value)} className="form-control ms-2 foot-input" id="Learn moreLink" placeholder="Learn more Link" />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default JourneySection;