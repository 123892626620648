// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
  max-width: 1930px !important;
}

input {
  color: #14148f !important;
}

textarea {
  color: #14148f !important;
}
.profile{
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}
.dropbtn{
  background: none !important;
}

@media only screen and (max-width: 600px) {
  .mob-sticky {
    background-color: white !important;
    position: fixed;
    z-index: 999;
  }

  .logo {
    width: 48% !important;
  }
  .loader{
    width: 18% !important;
  }

  .main {
    margin-top: 10% !important;
  }

  .w-50 {
    width: 100% !important;
  }

  img {
    width: 100% !important;
  }

  .css-1kxd7on-MuiDialogContent-root .d-flex {
    display: block !important;
  }
  .foot-input {
    margin-top: 5px !important;
    width: 100% !important;
  }
  .multi-input .d-flex{
    display: block !important;
  }
  .multi-input input{
    margin-top: 7px !important;
    width: 100%  !important;
  }
  .multi-input textarea{
    width: 100% !important;
  }
}

.cms-section .dash_text{
  font-size: 16px !important;
  text-wrap: nowrap !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,6BAA6B;AAC/B;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE;IACE,kCAAkC;IAClC,eAAe;IACf,YAAY;EACd;;EAEA;IACE,qBAAqB;EACvB;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,0BAA0B;IAC1B,sBAAsB;EACxB;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,0BAA0B;IAC1B,uBAAuB;EACzB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;AAC9B","sourcesContent":[".container-fluid {\n  max-width: 1930px !important;\n}\n\ninput {\n  color: #14148f !important;\n}\n\ntextarea {\n  color: #14148f !important;\n}\n.profile{\n  width: 40px !important;\n  height: 40px !important;\n  border-radius: 50% !important;\n}\n.dropbtn{\n  background: none !important;\n}\n\n@media only screen and (max-width: 600px) {\n  .mob-sticky {\n    background-color: white !important;\n    position: fixed;\n    z-index: 999;\n  }\n\n  .logo {\n    width: 48% !important;\n  }\n  .loader{\n    width: 18% !important;\n  }\n\n  .main {\n    margin-top: 10% !important;\n  }\n\n  .w-50 {\n    width: 100% !important;\n  }\n\n  img {\n    width: 100% !important;\n  }\n\n  .css-1kxd7on-MuiDialogContent-root .d-flex {\n    display: block !important;\n  }\n  .foot-input {\n    margin-top: 5px !important;\n    width: 100% !important;\n  }\n  .multi-input .d-flex{\n    display: block !important;\n  }\n  .multi-input input{\n    margin-top: 7px !important;\n    width: 100%  !important;\n  }\n  .multi-input textarea{\n    width: 100% !important;\n  }\n}\n\n.cms-section .dash_text{\n  font-size: 16px !important;\n  text-wrap: nowrap !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
