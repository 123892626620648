import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../../src/custom.css";
import Logout from "../../Logout";
import Loader from "../../Loader";
import Dropdown from "../../Dropdown";
import apiFunctions from "../../../apiKit/api";
import ReduxComponent from "../../../components/reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
const StoryofChange = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    //Our approach
    const [title, setTitle] = useState("");
    const [subtitle, setsubTitle] = useState("");
    const [comtitle, setcomTitle] = useState("");
    const [comDescription, setComDescription] = useState("");
    const [Imptitle, setImpTitle] = useState("");
    const [ImpDescription, setImpDescription] = useState("");
    const [logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const { storydata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(storydata, "how to work data");
        setData(storydata);
    }, [storydata])


    const setData = (data) => {
        const approach = JSON.parse(data[0]?.content);       
        setTitle(approach?.title);
        setsubTitle(approach?.orangetxt);
        setcomTitle(approach?.comtitle);
        setComDescription(approach?.comdescription);
        setImpTitle(approach?.Imptitle);
        setImpDescription(approach?.Impdescription);
        setLogo(appConstants.imageUrl + data[0]?.images);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": title,
            "orangetxt": subtitle,
            "comtitle": comtitle,
            "comdescription": comDescription,
            "Imptitle": Imptitle,
            "Impdescription": ImpDescription
        }
        if (LogoFile) {
            form.append("image", LogoFile)
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsStoryofchangeContent(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }



    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row mt-3 main">
                                <Card className="p-2">
                                    <CardContent>
                                        {/* StoryofChange section */}
                                        <div className="wework-section">
                                            <h5><b>Story of Change contents</b></h5>
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Featured Stories</label>
                                                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="Featured Stories" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Transforming Lives Through Support</label>
                                                            <input value={subtitle} onChange={(e) => setsubTitle(e.target.value)} type="text" className="form-control" placeholder="Transforming Lives Through Support" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <label>Logo</label>
                                                        <div>
                                                            {logo ?
                                                                <div style={{ width: "300px" }}>
                                                                    <img className="input-img" src={logo} alt="logo" />
                                                                </div> : ''}
                                                            <div className="mt-2 d-flex">
                                                                <input
                                                                    className="form-control foot-input"
                                                                    onChange={(e) => {
                                                                        const file = e.target.files[0];
                                                                        if (file) {
                                                                            const objectUrl = URL.createObjectURL(file);
                                                                            setLogoFile(file);
                                                                            setLogo(objectUrl);
                                                                        }
                                                                    }}
                                                                    type="file"
                                                                    accept="image/png, image/jpeg,image/webp"
                                                                />
                                                                <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">
                                                    <div className="col-lg-6 col-12">
                                                        <label>Community Impact</label>
                                                        <input value={comtitle} onChange={(e) => setcomTitle(e.target.value)} type="text" className="form-control" placeholder="Community Impact" />
                                                        <textarea rows={4} value={comDescription} onChange={(e) => setComDescription(e.target.value)} type="text" className="form-control mt-2" placeholder="Description" />
                                                    </div>
                                                    <div className="col-lg-6 col-12">
                                                        <label>Create Impact</label>
                                                        <input value={Imptitle} onChange={(e) => setImpTitle(e.target.value)} type="text" className="form-control" placeholder="Create Impact" />
                                                        <textarea rows={4} value={ImpDescription} onChange={(e) => setImpDescription(e.target.value)} type="text" className="form-control mt-2" placeholder="Description" />
                                                    </div>
                                                </div>
                                                <div className="text-start">
                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                </div>
                                            </form>
                                        </div>
                                    </CardContent>
                                </Card>


                            </div>
                        }

                    </div>
                </div>
            </div >
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default StoryofChange;