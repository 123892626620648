import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../../src/custom.css";
import Logout from "../../Logout";
import Loader from "../../Loader";
import Dropdown from "../../Dropdown";
import apiFunctions from "../../../apiKit/api";
import ReduxComponent from "../../../components/reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import PartnerSpeakTable from "./partnertable";
const PartnerSpeak = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    //Our approach
    const [title, setTitle] = useState("");
    const [subtitle, setsubTitle] = useState("");
    const [description, setDescription] = useState("");

    const { partnerspeakdata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(partnerspeakdata, "partnerspeak data");
        setData(partnerspeakdata);
    }, [partnerspeakdata])


    const setData = (data) => {
        const approach = JSON.parse(data?.details?.[0]?.content);     
        setTitle(approach?.title);
        setsubTitle(approach?.orangetxt);
        setDescription(approach?.description);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": title,
            "orangetxt": subtitle,
            "description": description
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsPartnerspeakContent(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }



    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row mt-3 main">
                                <Card className="p-2">
                                    <CardContent>
                                        {/* PartnerSpeak section */}
                                        <div className="wework-section">
                                            <h5><b>Partner Speak contents</b></h5>
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>At World Vision India, our sponsors & donors play a vital role in</label>
                                                            <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" placeholder="At World Vision India" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label> transforming the lives of children and communities.</label>
                                                            <input value={subtitle} onChange={(e) => setsubTitle(e.target.value)} type="text" className="form-control" placeholder="transforming the lives of children" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description</label>
                                                            <input value={description} onChange={(e) => setDescription(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="text-start">
                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                </div>

                                            </form>
                                        </div>

                                        <div>
                                            <PartnerSpeakTable />
                                        </div>
                                    </CardContent>
                                </Card>


                            </div>
                        }

                    </div>
                </div>
            </div >
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default PartnerSpeak;