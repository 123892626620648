import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import apiFunctions from "../../../apiKit/api";
import { fetchAPIs } from "../../../redux/slice/slice";
import { useDispatch, useSelector } from "react-redux";
const ChildSponserSection = () => {
    const { cmsHomeChilddata } = useSelector((state) => state.api);
    const dispatch = useDispatch();

    // variables
    const [childTitle, setChildTitle] = useState("");
    const [healthTitle, setHealthTitle] = useState("");
    const [protectedTitle, setProtectedTitle] = useState("");
    const [educate, setEducate] = useState("");
    const [descriptionOne, setDescriptionOne] = useState("");
    const [descriptionTwo, setDescriptionTwo] = useState("");
    const [descriptionThree, setDescriptionThree] = useState("");
    const [descriptionFour, setDescriptionFour] = useState("");
    const [sponsText, setSponsText] = useState("");
    const [sponsLink, setSponsLink] = useState("");



    const setData = async (data) => {
        setChildTitle(data?.title);
        setHealthTitle(data?.health);
        setProtectedTitle(data?.protected);
        setEducate(data?.educate);
        setDescriptionOne(data?.descriptionone);
        setDescriptionTwo(data?.descriptiontwo);
        setDescriptionThree(data?.descriptionthree);
        setDescriptionFour(data?.descriptionfour);
        setSponsText(data?.sponstext);
        setSponsLink(data?.sponslink);
    }

    useEffect(() => {
        setData(JSON.parse(cmsHomeChilddata[0]?.content));
    }, [cmsHomeChilddata])

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "child_sponsorship",
            "content": {
                "title": childTitle,
                "health": healthTitle,
                "protected": protectedTitle,
                "educate": educate,
                "descriptionone": descriptionOne,
                "descriptiontwo": descriptionTwo,
                "descriptionthree": descriptionThree,
                "descriptionfour": descriptionFour,
                "sponstext": sponsText,
                "sponslink": sponsLink,
            }
        }
        apiFunctions.updateCmsHomeChildSponserapi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }
    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-2">
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    <h5><b>Title</b></h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Child Sponsership</label>
                                            <input value={childTitle} onChange={(e) => setChildTitle(e.target.value)} type="text" className="form-control" placeholder="Title" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Enjoy Better Health</label>
                                            <input value={healthTitle} onChange={(e) => setHealthTitle(e.target.value)} type="text" className="form-control" placeholder="Enjoy Better Health" />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label>Stay Protected</label>
                                            <input value={protectedTitle} onChange={(e) => setProtectedTitle(e.target.value)} type="text" className="form-control" placeholder="Stay Protected" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Have Access to Educate</label>
                                            <input value={educate} onChange={(e) => setEducate(e.target.value)} type="text" className="form-control" placeholder="Have Access" />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <h5><b>Description</b></h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Enjoy Better Health</label>
                                            <textarea value={descriptionOne} onChange={(e) => setDescriptionOne(e.target.value)} rows={3} className="form-control" placeholder="Enjoy Better Health" />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label>Stay Protected</label>
                                            <textarea value={descriptionTwo} onChange={(e) => setDescriptionTwo(e.target.value)} rows={3} className="form-control" placeholder="Stay Protected" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Tax Deductions</label>
                                            <textarea value={descriptionThree} onChange={(e) => setDescriptionThree(e.target.value)} rows={3} className="form-control" placeholder="Tax deductions" />
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label>Exchange Letter</label>
                                            <textarea value={descriptionFour} onChange={(e) => setDescriptionFour(e.target.value)} rows={3} className="form-control" placeholder="Exchange letter" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <div className="row multi-input">
                                        <div className="d-flex">
                                            <div >
                                                <h6 style={{ textWrap: "nowrap" }} className="mt-2">Become a Sponsor Today:</h6>
                                            </div>
                                            <input value={sponsText} onChange={(e) => setSponsText(e.target.value)} type="text" className="form-control ms-2 foot-input" id="ourhistory" placeholder="Become a Sponsor Today" />
                                            <input value={sponsLink} onChange={(e) => setSponsLink(e.target.value)} type="text" className="form-control ms-2 foot-input" id="ourhistorylink" placeholder="Become a Sponsor Today Link" />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default ChildSponserSection;