import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchAPIs } from "../../../redux/slice/slice";
import Swal from "sweetalert2";
import apiFunctions from "../../../apiKit/api";
import { useDispatch, useSelector } from "react-redux";
const ImpactSection = () => {
    const { presencedata, Impactdata } = useSelector((state) => state.api);
    const dispatch = useDispatch();
    //impact section
    const [impTitle, setImpTitle] = useState("");
    const [impYear, setImpYear] = useState("");
    const [impChildCount, setImpChildCount] = useState("");
    const [impComCount, setImpComcount] = useState("");
    const [impStateCount, setImpStatecount] = useState("");
    //presence section
    const [presenceTitle, setPresenceTitle] = useState("");
    const [presenceDescription, setPresenceDescription] = useState("");
    useEffect(() => {
        setData(JSON.parse(Impactdata[0]?.content), JSON.parse(presencedata[0]?.content));
    }, [presencedata, Impactdata])
    const setData = async (impact, presence) => {
        //impact
        setImpTitle(impact?.title);
        setImpYear(impact?.year);
        setImpChildCount(impact?.child);
        setImpStatecount(impact?.state);
        setImpComcount(impact?.community);
        //presence
        setPresenceTitle(presence?.title);
        setPresenceDescription(presence?.description);
    }

    const handleImpactSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "our_impact",
            "content": {
                "title": impTitle,
                "year": impYear,
                "child": impChildCount,
                "state": impStateCount,
                "community": impComCount
            }
        }
        apiFunctions.updateCmsHomeChildSponserapi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }

    const handlePresenceSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "our_presence",
            "content": {
                "title": presenceTitle,
                "description": presenceDescription
            }
        }
        apiFunctions.updateCmsHomeChildSponserapi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }
    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-2">
                        <CardContent>
                            <form onSubmit={handleImpactSubmit}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4 col-12">
                                            <label>Title</label>
                                            <textarea rows={2} className="form-control" type="text" onChange={(e) => setImpTitle(e.target.value)} value={impTitle} />
                                        </div>
                                        <div className="col"></div>

                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg col-12">
                                            <label>Child-count</label>
                                            <input className="form-control" type="text" onChange={(e) => setImpChildCount(e.target.value)} value={impChildCount} />
                                        </div>
                                        <div className="col-lg col-12">
                                            <label>Community-count</label>
                                            <input className="form-control" type="text" onChange={(e) => setImpComcount(e.target.value)} value={impComCount} />
                                        </div>
                                        <div className="col-lg col-12">
                                            <label>Sponsers-count</label>
                                            <input className="form-control" type="text" onChange={(e) => setImpYear(e.target.value)} value={impYear} />
                                        </div>
                                        <div className="col-lg col-12">
                                            <label>Partners-count</label>
                                            <input className="form-control" type="text" onChange={(e) => setImpStatecount(e.target.value)} value={impStateCount} />
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                    <h5 className="mt-4 ms-3">Our Presence section</h5>
                    <Card className="p-2">
                        <CardContent>
                            <form onSubmit={handlePresenceSubmit} >
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label>Title</label>
                                            <input className="form-control" onChange={(e) => setPresenceTitle(e.target.value)} value={presenceTitle} type="text" placeholder="Our Presence" />
                                        </div>
                                        <div className="col-lg"></div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-6 col-12">
                                            <label>description</label>
                                            <textarea className="form-control" onChange={(e) => setPresenceDescription(e.target.value)} value={presenceDescription} type="text" placeholder="description" rows={3} />
                                        </div>
                                        <div className="col-lg"></div>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default ImpactSection;