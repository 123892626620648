import React, { useEffect, useState } from "react";
import SideBar from "../Sidebar"
import { Card, CardContent, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../src/custom.css";
import "../../../src/assets/css/cms.css";
import Logout from "../Logout";

import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../redux/slice/slice";
import ReduxComponent from "../reduxData";
import Swal from "sweetalert2";
import apiFunctions from "../../apiKit/api";
import Dropdown from "../Dropdown";
import { appConstants } from "../../apiKit/appConstant";
const Footer = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const { footerdata } = useSelector((state) => state.api);
    const [appstoreLogo, setAppstoreLogo] = useState(null);
    const [playstoreLogo, setplaystoreLogo] = useState(null);
    const [appstoreLogoFile, setAppstoreLogoFile] = useState(null);
    const [playstoreLogoFile, setplaystoreLogoFile] = useState(null);
    const dispatch = useDispatch();

    //variables
    //get news section
    const [getNews, setGetNews] = useState("");
    const [getNewsDescription, setGetNewsDescription] = useState("");
    const [getNewsSubscribe, setGetNewsSubscribe] = useState("");

    //about
    const [about, setAbout] = useState("");
    const [ourhistory, setOurhistory] = useState("");
    const [ourhistorylink, setOurhistorylink] = useState("");
    const [story, setStory] = useState("");
    const [storylink, setStorylink] = useState("");
    const [partner, setPartner] = useState("");
    const [partnerlink, setPartnerlink] = useState("");
    const [howtowork, setHowtowork] = useState("");
    const [howtoworklink, sethowtoworklink] = useState("");
    const [leadership, setLeadership] = useState("");
    const [leadershiplink, setLeadershiplink] = useState("");
    //Get Involved
    const [getinvolve, setGetInvolve] = useState("");
    const [spons, setSpons] = useState("");
    const [sponslink, setSponslink] = useState("");
    const [corporate, setCorporate] = useState("");
    const [corporatelink, setCorporatelink] = useState("");
    const [savelife, setSavelife] = useState("");
    const [savelifelink, setSavelifelink] = useState("");
    const [contactus, setContactus] = useState("");
    const [contactuslink, setContactuslink] = useState("");
    //Way to give
    const [waytogive, setwaytogive] = useState("");
    const [realgifts, setRealgifts] = useState("");
    const [realgiftslink, setRealgiftslink] = useState("");
    const [giftcat, setgiftCat] = useState("");
    const [giftcatlink, setgiftCatlink] = useState("");
    const [backtoschool, setBacktoschool] = useState("");
    const [backtoschoollink, setBacktoschoollink] = useState("");
    const [wheremost, setWheremost] = useState("");
    const [wheremostlink, setWheremostlink] = useState("");
    const [needed, setNeeded] = useState("");
    const [neededlink, setNeededlink] = useState("");
    const [emergency, setEmergency] = useState("");
    const [emergencylink, setEmergencylink] = useState("");
    //Media&resources
    const [media, setMedia] = useState("");
    const [press, setPress] = useState("");
    const [presslink, setPresslink] = useState("");
    const [gallery, setGallery] = useState("");
    const [gallerylink, setGallerylink] = useState("");
    const [inNews, setInNews] = useState("");
    const [inNewslink, setInNewslink] = useState("");
    const [publications, setPublications] = useState("");
    const [publicationslink, setPublicationsLink] = useState("");
    const [blog, setBlog] = useState("");
    const [bloglink, setBlogLink] = useState("");
    //Get in touch
    const [getintouch, setgetIntouch] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [email, setEmail] = useState("");
    //Download app
    const [downloadapp, setDownloadapp] = useState("");
    const [appstoreUrl, setAppstoreUrl] = useState("");
    const [playstoreUrl, setPlaystoreUrl] = useState("");
    const [follow, setFollow] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [instaLink, setInstaLink] = useState("");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [copyrights, setCopyrights] = useState("");



    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const setFooterData = async (data) => {
        setGetNews(data?.contents?.getnews);
        setGetNewsDescription(data?.contents?.getnewsdescription);
        setGetNewsSubscribe(data?.contents?.getsubscribe);
        setAbout(data?.contents?.about);
        setOurhistory(data?.contents?.ourhistory);
        setOurhistorylink(data?.contents?.ourhistorylink);
        setStory(data?.contents?.story);
        setStorylink(data?.contents?.storylink);
        setPartner(data?.contents?.partner);
        setPartnerlink(data?.contents?.partnerlink);
        setHowtowork(data?.contents?.how);
        sethowtoworklink(data?.contents?.howlink);
        setLeadership(data?.contents?.leadership);
        setLeadershiplink(data?.contents?.leadershiplink);
        setGetInvolve(data?.contents?.getinvolve);
        setSpons(data?.contents?.sponser);
        setSponslink(data?.contents?.sponserlink);
        setCorporate(data?.contents?.corporate);
        setCorporatelink(data?.contents?.corporatelink);
        setSavelife(data?.contents?.savelife);
        setSavelifelink(data?.contents?.savelifelink);
        setContactus(data?.contents?.contact);
        setContactuslink(data?.contents?.contactlink);
        setwaytogive(data?.contents?.waytogive);
        setRealgifts(data?.contents?.realgifts);
        setRealgiftslink(data?.contents?.realgiftslink);
        setgiftCat(data?.contents?.giftcat);
        setgiftCatlink(data?.contents?.giftcatlink);
        setBacktoschool(data?.contents?.backtoschool);
        setBacktoschoollink(data?.contents?.backtoschool);
        setWheremost(data?.contents?.wheremost);
        setWheremostlink(data?.contents?.wheremostlink);
        setNeeded(data?.contents?.needed);
        setNeededlink(data?.contents?.neededlink);
        setEmergency(data?.contents?.emergency);
        setEmergencylink(data?.contents?.emergencylink);
        setMedia(data?.contents?.media);
        setPress(data?.contents?.press);
        setPresslink(data?.contents?.presslink);
        setGallery(data?.contents?.gallery);
        setGallerylink(data?.contents?.gallerylink);
        setInNews(data?.contents?.innews);
        setInNewslink(data?.contents?.innewslink);
        setPublications(data?.contents?.publications);
        setPublicationsLink(data?.contents?.publicationslink);
        setBlog(data?.contents?.blog);
        setBlogLink(data?.contents?.bloglink);
        setgetIntouch(data?.contents?.getintouch);
        setAddress(data?.contents?.address);
        setPhone(data?.contents?.phone);
        setFax(data?.contents?.fax);
        setEmail(data?.contents?.email);
        setDownloadapp(data?.contents?.downloadapp);
        setAppstoreUrl(data?.contents?.appstorelink);
        setPlaystoreUrl(data?.contents?.playstorelink);
        setFollow(data?.contents?.follow);
        setYoutubeLink(data?.contents?.youtubeLink);
        setFacebook(data?.contents?.facebookLink);
        setLinkedin(data?.contents?.linkedinLink);
        setInstaLink(data?.contents?.instagramLink);
        setCopyrights(data?.contents?.copyrights);
        setAppstoreLogo(appConstants.imageUrl + data?.images?.footerImage1);
        setplaystoreLogo(appConstants.imageUrl + data?.images?.footerImage2);
    }

    useEffect(() => {
        // dispatch(fetchAPIs());
        setFooterData(footerdata[0]);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(footerdata, 'footerdata');
    }, [footerdata])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("fieldName", "footer");
        var footJson = {
            "getnews": getNews,
            "getnewsdescription": getNewsDescription,
            "getsubscribe": getNewsSubscribe,
            "about": about,
            "ourhistory": ourhistory,
            "ourhistorylink": ourhistorylink,
            "story": story,
            "storylink": storylink,
            "partner": partner,
            "partnerlink": partnerlink,
            "how": howtowork,
            "howlink": howtoworklink,
            "leadership": leadership,
            "leadershiplink": leadershiplink,
            "getinvolve": getinvolve,
            "sponser": spons,
            "sponserlink": sponslink,
            "corporate": corporate,
            "corporatelink": corporatelink,
            "savelife": savelife,
            "savelifelink": savelifelink,
            "contact": contactus,
            "contactlink": contactuslink,
            "waytogive": waytogive,
            "realgifts": realgifts,
            "realgiftslink": realgiftslink,
            "giftcat": giftcat,
            "giftcatlink": giftcatlink,
            "backtoschool": backtoschool,
            "backtoschoollink": backtoschoollink,
            "wheremost": wheremost,
            "wheremostlink": wheremostlink,
            "needed": needed,
            "neededlink": neededlink,
            "emergency": emergency,
            "emergencylink": emergencylink,
            "media": media,
            "press": press,
            "presslink": presslink,
            "gallery": gallery,
            "gallerylink": gallerylink,
            "innews": inNews,
            "innewslink": inNewslink,
            "publications": publications,
            "publicationslink": publicationslink,
            "blog": blog,
            "bloglink": bloglink,
            "getintouch": getintouch,
            "address": address,
            "phone": phone,
            "fax": fax,
            "email": email,
            "downloadapp": downloadapp,
            "appstorelink": appstoreUrl,
            "follow": follow,
            "playstorelink": playstoreUrl,
            "youtubeLink": youtubeLink,
            "facebookLink": facebook,
            "linkedinLink": linkedin,
            "instagramLink": instaLink,
            "copyrights": copyrights
        }

        formData.append("contents", JSON.stringify(footJson));

        if (appstoreLogoFile) {
            formData.append("footerImage1", appstoreLogoFile);
        }
        if (playstoreLogoFile) {
            formData.append("footerImage2", playstoreLogoFile);
        }

        apiFunctions.CmsHeaderFooterUpdateapi(formData).then((res) => {
            if (res.status === 200) {
                console.log("success");
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >

                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-4 main">
                                <div className=" row sub-menu p-0">
                                    <div className=" col-12">
                                        {/* <div className="p-3">
                                        <Button className="btn" onClick={handleEdit} >Edit</Button>
                                    </div> */}
                                        <Card>
                                            <CardContent>
                                                <form onSubmit={handleSubmit}>
                                                    <div className="container">
                                                        {/* Top headers */}
                                                        <div className="text-start mt-4"><h5 className="fw-bold">Footer Contents</h5></div>
                                                        <div>
                                                            <div className="text-start mt-3"><h6 className="fw-bold">Get the Latest news and updates</h6></div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-8 col-12">
                                                                    <label>Title</label>
                                                                    <input value={getNews} onChange={(e) => setGetNews(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-8 col-12">
                                                                    <label>description</label>
                                                                    <textarea value={getNewsDescription} onChange={(e) => setGetNewsDescription(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-8 col-12">
                                                                    <label>Subscribe</label>
                                                                    <input value={getNewsSubscribe} onChange={(e) => setGetNewsSubscribe(e.target.value)} className=" foot-input form-control" type="text" placeholder="subscribe" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* About Us */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">About Us</h6></div>
                                                            <div className="row">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={about} onChange={(e) => setAbout(e.target.value)} className="form-control ms-2 foot-input" placeholder="About Us" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Our History:</h6>
                                                                    </div>
                                                                    <input type="text" value={ourhistory} onChange={(e) => setOurhistory(e.target.value)} className="form-control ms-2 foot-input" id="ourhistory" placeholder="Our History" />
                                                                    <input type="text" value={ourhistorylink} onChange={(e) => setOurhistorylink(e.target.value)} className="form-control ms-2 foot-input" id="ourhistorylink" placeholder="Our History Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Stories of change:</h6>
                                                                    </div>
                                                                    <input type="text" value={story} onChange={(e) => setStory(e.target.value)} className="form-control ms-2 foot-input" id="stories" placeholder="Stories of change" />
                                                                    <input type="text" value={storylink} onChange={(e) => setStorylink(e.target.value)} className="form-control ms-2 foot-input" id="stories" placeholder="Stories of change Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Partner's Speak:</h6>
                                                                    </div>
                                                                    <input type="text" value={partner} onChange={(e) => setPartner(e.target.value)} className="form-control ms-2 foot-input" id="ourhistory" placeholder="Partner speak" />
                                                                    <input type="text" value={partnerlink} onChange={(e) => setPartnerlink(e.target.value)} className="form-control ms-2 foot-input" id="ourhistorylink" placeholder="Partner speak Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">How to work:</h6>
                                                                    </div>
                                                                    <input type="text" value={howtowork} onChange={(e) => setHowtowork(e.target.value)} className="form-control ms-2 foot-input" id="ourhistory" placeholder="How to work" />
                                                                    <input type="text" value={howtoworklink} onChange={(e) => sethowtoworklink(e.target.value)} className="form-control ms-2 foot-input" id="ourhistorylink" placeholder="How to work" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Leadership Team:</h6>
                                                                    </div>
                                                                    <input type="text" value={leadership} onChange={(e) => setLeadership(e.target.value)} className="form-control ms-2 foot-input" id="leader" placeholder="Leadership" />
                                                                    <input type="text" value={leadershiplink} onChange={(e) => setLeadershiplink(e.target.value)} className="form-control ms-2 foot-input" id="leader" placeholder="Leadership Link" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Get Involved */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">Get Involved</h6></div>
                                                            <div className="row">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={getinvolve} onChange={(e) => setGetInvolve(e.target.value)} className="form-control ms-2 foot-input" placeholder="Get Involved" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Sponser A Child:</h6>
                                                                    </div>
                                                                    <input type="text" value={spons} onChange={(e) => setSpons(e.target.value)} className="form-control ms-2 foot-input" id="ourhistory" placeholder="Sponser A Child" />
                                                                    <input type="text" value={sponslink} onChange={(e) => setSponslink(e.target.value)} className="form-control ms-2 foot-input" id="ourhistorylink" placeholder="Sponser A Child Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">For Corporates:</h6>
                                                                    </div>
                                                                    <input type="text" value={corporate} onChange={(e) => setCorporate(e.target.value)} className="form-control ms-2 foot-input" id="For Corporates" placeholder="For Corporates" />
                                                                    <input type="text" value={corporatelink} onChange={(e) => setCorporatelink(e.target.value)} className="form-control ms-2 foot-input" id="For Corporates" placeholder="For Corporates Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Save Life:</h6>
                                                                    </div>
                                                                    <input type="text" value={savelife} onChange={(e) => setSavelife(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Save Life" />
                                                                    <input type="text" value={savelifelink} onChange={(e) => setSavelifelink(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Save Life Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Contact Us:</h6>
                                                                    </div>
                                                                    <input type="text" value={contactus} onChange={(e) => setContactus(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Contact Us" />
                                                                    <input type="text" value={contactuslink} onChange={(e) => setContactuslink(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Contact Us Link" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Way to Give */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">Way to Give</h6></div>
                                                            <div className="row">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={waytogive} onChange={(e) => setwaytogive(e.target.value)} className="form-control ms-2 foot-input" placeholder="Way to Give" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Real Gifts:</h6>
                                                                    </div>
                                                                    <input type="text" value={realgifts} onChange={(e) => setRealgifts(e.target.value)} className="form-control ms-2 foot-input" id="Real Gifts" placeholder="Real Gifts" />
                                                                    <input type="text" value={realgiftslink} onChange={(e) => setRealgiftslink(e.target.value)} className="form-control ms-2 foot-input" id="Real Gifts" placeholder="Real Gifts Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Gift Catelogue:</h6>
                                                                    </div>
                                                                    <input type="text" value={giftcat} onChange={(e) => setgiftCat(e.target.value)} className="form-control ms-2 foot-input" id="Gift Catelogue" placeholder="Gift Catelogue" />
                                                                    <input type="text" value={giftcatlink} onChange={(e) => setgiftCatlink(e.target.value)} className="form-control ms-2 foot-input" id="Gift Catelogue" placeholder="Gift Catelogue Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Back to School:</h6>
                                                                    </div>
                                                                    <input type="text" value={backtoschool} onChange={(e) => setBacktoschool(e.target.value)} className="form-control ms-2 foot-input" id="Back to School" placeholder="Back to School" />
                                                                    <input type="text" value={backtoschoollink} onChange={(e) => setBacktoschoollink(e.target.value)} className="form-control ms-2 foot-input" id="Back to School" placeholder="Back to School Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Where Most:</h6>
                                                                    </div>
                                                                    <input type="text" value={wheremost} onChange={(e) => setWheremost(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Where Most" />
                                                                    <input type="text" value={wheremostlink} onChange={(e) => setWheremostlink(e.target.value)} className="form-control ms-2 foot-input" id="Save Life" placeholder="Where Most Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Needed:</h6>
                                                                    </div>
                                                                    <input type="text" value={needed} onChange={(e) => setNeeded(e.target.value)} className="form-control ms-2 foot-input" id="Needed" placeholder="Needed" />
                                                                    <input type="text" value={neededlink} onChange={(e) => setNeededlink(e.target.value)} className="form-control ms-2 foot-input" id="Needed" placeholder="Needed Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Emergency Relief:</h6>
                                                                    </div>
                                                                    <input type="text" value={emergency} onChange={(e) => setEmergency(e.target.value)} className="form-control ms-2 foot-input" id="Emergency Relief" placeholder="Emergency Relief" />
                                                                    <input type="text" value={emergencylink} onChange={(e) => setEmergencylink(e.target.value)} className="form-control ms-2 foot-input" id="Emergency Relief" placeholder="Emergency Relief Link" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Media& Resources*/}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">Media & Resources</h6></div>
                                                            <div className="row">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={media} onChange={(e) => setMedia(e.target.value)} className="form-control ms-2 foot-input" placeholder="Media & Resources" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Press Releases:</h6>
                                                                    </div>
                                                                    <input type="text" value={press} onChange={(e) => setPress(e.target.value)} className="form-control ms-2 foot-input" id="Press Releases" placeholder="Press Releases" />
                                                                    <input type="text" value={presslink} onChange={(e) => setPresslink(e.target.value)} className="form-control ms-2 foot-input" id="Press Releases" placeholder="Press Releases Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Gallery:</h6>
                                                                    </div>
                                                                    <input type="text" value={gallery} onChange={(e) => setGallery(e.target.value)} className="form-control ms-2 foot-input" id="Gallery" placeholder="Gallery" />
                                                                    <input type="text" value={gallerylink} onChange={(e) => setGallerylink(e.target.value)} className="form-control ms-2 foot-input" id="Gallery" placeholder="Gallery Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">In the News:</h6>
                                                                    </div>
                                                                    <input value={inNews} onChange={(e) => setInNews(e.target.value)} type="text" className="form-control ms-2 foot-input" id="In the News" placeholder="In the News" />
                                                                    <input value={inNewslink} onChange={(e) => setInNewslink(e.target.value)} type="text" className="form-control ms-2 foot-input" id="In the News" placeholder="In the News Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Publications:</h6>
                                                                    </div>
                                                                    <input type="text" value={publications} onChange={(e) => setPublications(e.target.value)} className="form-control ms-2 foot-input" id="Publications" placeholder="Publications" />
                                                                    <input type="text" value={publicationslink} onChange={(e) => setPublicationsLink(e.target.value)} className="form-control ms-2 foot-input" id="Publications" placeholder="Publications Link" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Blog:</h6>
                                                                    </div>
                                                                    <input type="text" value={blog} onChange={(e) => setBlog(e.target.value)} className="form-control ms-2 foot-input" id="Blog" placeholder="Blog" />
                                                                    <input type="text" value={bloglink} onChange={(e) => setBlogLink(e.target.value)} className="form-control ms-2 foot-input" id="Blog" placeholder="Blog Link" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Get in Touch*/}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">Get in Touch</h6></div>
                                                            <div className="row multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={getintouch} onChange={(e) => setgetIntouch(e.target.value)} className="form-control ms-2 foot-input" placeholder="Get in Touch" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Address:</h6>
                                                                    </div>
                                                                    <textarea rows={4} value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control ms-2 foot-input" id="Address" placeholder="Address" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Phone:</h6>
                                                                    </div>
                                                                    <textarea rows={3} type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control ms-2 foot-input" id="Phone" placeholder="Phone" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Fax:</h6>
                                                                    </div>
                                                                    <input type="text" value={fax} onChange={(e) => setFax(e.target.value)} className="form-control ms-2 foot-input" id="Fax" placeholder="Fax" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 multi-input">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-2">Email:</h6>
                                                                    </div>
                                                                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control ms-2 foot-input" id="Email" placeholder="Email" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {/* Download our app*/}
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="text-start"><h6 className="fw-bold mt-4">Download Our App</h6></div>

                                                                <div className="row">
                                                                    <div className="d-flex">
                                                                        <div style={{ width: "150px" }}>
                                                                            <h6 style={{ textWrap: "nowrap" }} className="mt-1">Title</h6>
                                                                        </div>
                                                                        <input type="text" value={downloadapp} onChange={(e) => setDownloadapp(e.target.value)} className="form-control ms-2 foot-input" placeholder="Download Our App" />
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="text-start"><p className="fw-bold mt-1">App store</p></div>
                                                                    <div className="multi-input">
                                                                        <div style={{ width: "150px" }}>
                                                                            <img className="input-img mt-2" src={appstoreLogo} alt="demo" />
                                                                        </div><br />
                                                                        <div className="d-flex ">
                                                                            <input
                                                                                className="form-control foot-input"
                                                                                onChange={(e) => {
                                                                                    const file = e.target.files[0];
                                                                                    if (file) {
                                                                                        if (file.size <= 1048576) {
                                                                                            const objectUrl = URL.createObjectURL(file);
                                                                                            setAppstoreLogoFile(file);
                                                                                            setAppstoreLogo(objectUrl);
                                                                                        } else {
                                                                                            alert('File size exceeds 1MB limit. Please upload a smaller image.');
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                type="file"
                                                                                accept="image/png, image/jpeg"
                                                                            />
                                                                            <span className="mt-1 ms-2" style={{ fontWeight: "lighter", fontSize: "14px",color:"grey" }}>(150×50)</span>
                                                                        </div>
                                                                    </div>
                                                                    <input type="text" value={appstoreUrl} onChange={(e) => setAppstoreUrl(e.target.value)} className="form-control ms-2 foot-input mt-2" placeholder="App Store Link" />

                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="row mt-lg-5">
                                                                </div>
                                                                <div className="text-start"><p className="fw-bold mt-5">Play store</p></div>
                                                                <div className="row mt-2">
                                                                    <div className="multi-input">
                                                                        <div style={{ width: "150px" }}>
                                                                            <img className="input-img" src={playstoreLogo} alt="demo" />
                                                                        </div><br />
                                                                        <div className="d-flex">
                                                                            <input
                                                                                className="form-control foot-input"
                                                                                onChange={(e) => {
                                                                                    const file = e.target.files[0];
                                                                                    if (file) {
                                                                                        if (file.size <= 1048576) {
                                                                                            const objectUrl = URL.createObjectURL(file);
                                                                                            setplaystoreLogoFile(file);
                                                                                            setplaystoreLogo(objectUrl);
                                                                                        } else {
                                                                                            alert('File size exceeds 1MB limit. Please upload a smaller image.');
                                                                                        }
                                                                                    }
                                                                                }}
                                                                                type="file"
                                                                                accept="image/png, image/jpeg"
                                                                            />
                                                                            <span className="mt-1 ms-2" style={{ fontWeight: "lighter", fontSize: "14px",color:"grey" }}>(150×50)</span>
                                                                        </div>
                                                                    </div>

                                                                    <input type="text" value={playstoreUrl} onChange={(e) => setPlaystoreUrl(e.target.value)} className="form-control ms-2 foot-input mt-2" placeholder="Play Store Link" />
                                                                </div>
                                                            </div>

                                                        </div>

                                                        {/* Links */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-4">Follow Up</h6></div>
                                                            <div className="row">
                                                                <div className="d-flex">
                                                                    <div style={{ width: "150px" }}>
                                                                        <h6 style={{ textWrap: "nowrap" }} className="mt-1">Title</h6>
                                                                    </div>
                                                                    <input type="text" value={follow} onChange={(e) => setFollow(e.target.value)} className="form-control ms-2 foot-input" placeholder="Follow up" />
                                                                </div>
                                                            </div>
                                                            <div className="row mt-2 ">
                                                                <div className="col-lg-4 col-12 ">
                                                                    <label htmlFor="facebook" className="sr-only">facebook</label>
                                                                    <input type="text" value={facebook} onChange={(e) => setFacebook(e.target.value)} className="form-control" id="facebook" placeholder="facebook link" />
                                                                </div>
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="linkedin" className="sr-only">LinkedIn</label>
                                                                    <input type="text" value={linkedin} onChange={(e) => setLinkedin(e.target.value)} className="form-control" id="linkedin" placeholder="linkedin link" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                            <div className="row mt-2">
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="instagram" className="sr-only">Instagram</label>
                                                                    <input type="text" value={instaLink} onChange={(e) => setInstaLink(e.target.value)} className="form-control" id="instagram" placeholder="Instagram link" />
                                                                </div>
                                                                <div className="col-lg-4 col-12">
                                                                    <label htmlFor="youtube" className="sr-only">Youtube</label>
                                                                    <input type="text" value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} className="form-control" id="youtube" placeholder="Youtube link" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                        </div>

                                                        {/* Copyrights */}
                                                        <div>
                                                            <div className="text-start"><h6 className="fw-bold mt-3">Copyrights</h6></div>
                                                            <div className="row">
                                                                <div className="col-lg-6 col-12">
                                                                    <input type="text" value={copyrights} onChange={(e) => setCopyrights(e.target.value)} className="form-control" id="Copyrights" placeholder="Enter Copyrights" />
                                                                </div>
                                                                <div className="col-lg"></div>
                                                            </div>
                                                        </div>
                                                        <div className="text-start mt-4">
                                                            <button type="submit" className="btn">Update</button>
                                                        </div>

                                                    </div>
                                                </form>
                                            </CardContent>
                                        </Card>
                                    </div>

                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{background:"transparent"}}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Footer;