import method from "./method";
const apiFunctions = {
  //Default
  loginApi: (payload) => method.post("api/auth/login", payload),
  signUpApi: (payload) => method.post("register", payload),
  dataApi: (payload) => method.get("catlist", payload),
  resetPasswordApi: (payload) => method.post("api/auth/reset-password", payload),
  getDashboardApi: () => method.get("admin/cms/count"),
  //banners
  bannerApi: (payload) => method.get("admin/cms/banner-images", payload),
  bannerUpdateApi: (payload) => method.post("admin/cms/update/banner-images", payload),

  //FAQ
  getFAQApi: () => method.get("admin/cms/faq/get"),
  insertFAQApi: (payload) => method.post("admin/cms/faq/insert", payload),
  updateFAQApi: (payload) => method.post("admin/cms/faq/update", payload),
  deleteFAQApi: (payload) => method.post("admin/cms/faq/delete", payload),

  //cms api list
  //Header and Footer
  getCmsHomeapi: () => method.get("admin/cms/home"),
  getCmsHeaderapi: () => method.get("admin/cms/common/header"),
  CmsHeaderFooterUpdateapi: (payload) => method.post("admin/cms/header-footer-update", payload, true),
  getCmsFooterapi: () => method.get("admin/cms/common/footer"),

  //CmsHome
  //banners
  getCmsHomeBannerapi: () => method.get("admin/cms/get/home/banner_images"),
  updateCmsHomeBannerapi: (payload) => method.post("admin/cms/update/home/banner_images", payload, true),
  addCmsHomeBannerapi: (payload) => method.post("admin/cms/add/home/banner_images", payload, true),
  deleteCmsHomeBannerapi: (payload) => method.post("admin/cms/delete/home/banner_images", payload),
  //my-world-banners
  getMyworldBannerapi: () => method.get("admin/cms/get/world-banner/what_is_my_world"),
  updateMyworldBannerapi: (payload) => method.post("admin/cms/update/world-banner/what_is_my_world", payload, true),
  addMyworldBannerapi: (payload) => method.post("admin/cms/add/world-banner/what_is_my_world", payload, true),
  deleteMyworldBannerapi: (payload) => method.post("admin/cms/delete/world-banner/what_is_my_world", payload),
  // my-world-banners contents 
  getCmsMyworldcontentapi: () => method.get("admin/cms/world-banner/what_is_my_world"),
  updateCmsMyworldcontentapi: (payload) => method.post("admin/cms/insert/what_is_my_world", payload, true),
  //childsponsership 
  getCmsHomeChildSponserapi: () => method.get("admin/cms/home/child_sponsorship"),
  updateCmsHomeChildSponserapi: (payload) => method.post("admin/cms/update/home", payload),
  //wvi-journey 
  getCmsHomeJourneyapi: () => method.get("admin/cms/home/wvi_journey"),
  updateCmsHomeJourneyapi: (payload) => method.post("admin/cms/insert/wvi_journey", payload, true),
  //FocusArea
  addCmsHomeFocusAreaapi: (payload) => method.post("admin/cms/add/home/our_focus_areas", payload, true),
  getCmsHomeFocusAreaapi: () => method.get("admin/cms/get/home/our_focus_areas"),
  updateCmsHomeFocusAreaContent: (payload) => method.post("admin/cms/update/home", payload),
  deleteCmsHomeFocusAreaImage: (payload) => method.post("admin/cms/delete/home/our_focus_areas", payload),
  updateCmsHomeFocusAreaImage: (payload) => method.post("admin/cms/update/home/our_focus_areas", payload, true),
  //Impact-section 
  getCmsHomeImpactapi: () => method.get("admin/cms/home/our_impact"),
  updateCmsHomeImpactapi: (payload) => method.post("admin/cms/update/home", payload),
  //Presence-section 
  getCmsHomePresenceapi: () => method.get("admin/cms/home/our_presence"),
  updateCmsHomePresenceapi: (payload) => method.post("admin/cms/update/home", payload),
  //Two-banner 
  getCmsHomeTwobannerapi: () => method.get("admin/cms/home/two_banner_section"),
  updateCmsHomeTwobannerapi: (payload) => method.post("admin/cms/insert/two_banner_section", payload, true),
  //CSR-section 
  addCmsHomeCSRImage: (payload) => method.post("admin/cms/add/home/csr", payload, true),
  getCmsHomeCSRdata: () => method.get("admin/cms/get/home/csr"),
  updateCmsHomeCSRContent: (payload) => method.post("admin/cms/update/home", payload),
  deleteCmsHomeCSRImage: (payload) => method.post("admin/cms/delete/home/csr", payload),
  updateCmsHomeCSRImage: (payload) => method.post("admin/cms/update/home/csr", payload, true),
  //Inthe-news-section
  addCmsHomeInNewsImage: (payload) => method.post("admin/cms/add/home/in_the_news", payload, true),
  getCmsHomeInNewsdata: () => method.get("admin/cms/get/home/in_the_news"),
  updateCmsHomeInNewsContent: (payload) => method.post("admin/cms/update/home", payload),
  deleteCmsHomeInNewsImage: (payload) => method.post("admin/cms/delete/home/in_the_news", payload),
  updateCmsHomeInNewsImage: (payload) => method.post("admin/cms/update/home/in_the_news", payload, true),
  //Awards-section 
  addCmsHomeAwardImage: (payload) => method.post("admin/cms/add/home/awards", payload, true),
  getCmsHomeAwarddata: () => method.get("admin/cms/get/home/awards"),
  deleteCmsHomeAwardImage: (payload) => method.post("admin/cms/delete/home/awards", payload),
  updateCmsHomeAwardImage: (payload) => method.post("admin/cms/update/home/awards", payload, true),
  //campaign-section
  getCmsHomeCampaignapi: () => method.get("admin/cms/home/campaign_banner"),
  updateCmsHomeCampaignapi: (payload) => method.post("admin/cms/insert/campaign_banner", payload, true),
  //Faq-section
  getCmsHomeFAQContentapi: () => method.get("admin/cms/faq/get"),
  updateCmsHomeFAQcontentapi: (payload) => method.post("admin/cms/update/home", payload),
  //accrediation-section 
  addCmsHomeAccrediationImage: (payload) => method.post("admin/cms/add/home/accreditation", payload, true),
  getCmsHomeAccrediationdata: () => method.get("admin/cms/get/home/accreditation"),
  updateCmsHomeAccrediationContent: (payload) => method.post("admin/cms/update/home", payload),
  deleteCmsHomeAccrediationImage: (payload) => method.post("admin/cms/delete/home/accreditation", payload),
  updateCmsHomeAccrediationImage: (payload) => method.post("admin/cms/update/home/accreditation", payload, true),
  //Testimonial 
  getCmsHomeTestimonialdata: () => method.get("admin/cms/home/testimonials"),
  updateCmsHomeTestimonialContent: (payload) => method.post("admin/cms/update/home", payload),
  //staff-section 
  addCmsHomeStaffImage: (payload) => method.post("admin/cms/add/home/our_staff", payload, true),
  getCmsHomeStaffdata: () => method.get("admin/cms/get/home/our_staff"),
  deleteCmsHomeStaffImage: (payload) => method.post("admin/cms/delete/home/our_staff", payload),
  updateCmsHomeStaffImage: (payload) => method.post("admin/cms/update/home/our_staff", payload, true),
  //sponser-section  
  addCmsHomeSponserImage: (payload) => method.post("admin/cms/add/home/sponsors", payload, true),
  getCmsHomeSponserdata: () => method.get("admin/cms/get/home/sponsors"),
  deleteCmsHomeSponserImage: (payload) => method.post("admin/cms/delete/home/sponsors", payload),
  updateCmsHomeSponserImage: (payload) => method.post("admin/cms/update/home/sponsors", payload, true),
  //About
  // How we work
  addCmsAboutOurapproach: (payload) => method.post("admin/cms/insert/our_approach", payload, true),
  addCmsAboutProgramme: (payload) => method.post("admin/cms/insert/programme_areas", payload, true),
  addCmsAboutWheremoney: (payload) => method.post("admin/cms/insert/where_your_money_goes", payload, true),
  addCmsAboutGetinvolve: (payload) => method.post("admin/cms/insert/get_invloved", payload, true),
  getCmsAboutHowtoworkdata: () => method.get("admin/cms/get_page/how_we_work"),
  // Stories of change
  addCmsStoryofchangeContent: (payload) => method.post("admin/cms/insert/impact", payload, true),
  getCmsStoryofchangedata: () => method.get("admin/cms/get_page/story_of_change"),
  // partners-speak
  addCmsPartnerspeakContent: (payload) => method.post("admin/cms/insert/partner_speak", payload),
  getCmsPartnerspeakdata: () => method.get("admin/cms/partners/partner_speak"),
  //partners
  addCmsPartnersImage: (payload) => method.post("admin/cms/add/partners/partners", payload, true),
  getCmsPartnersdata: () => method.get("admin/cms/get/partners/partners"),
  deleteCmsPartnersImage: (payload) => method.post("admin/cms/delete/partners/partners", payload),
  updateCmsPartnersImage: (payload) => method.post("admin/cms/update/partners/partners", payload, true),
  // Leadership-guide
  addCmsLeadershipguideContent: (payload) => method.post("admin/cms/insert/guide", payload),
  // Leadership-Board-content
  addCmsLeadershipBoardContent: (payload) => method.post("admin/cms/update/leadership", payload),
  // Leadership-Steering-content
  addCmsLeadershipSteerContent: (payload) => method.post("admin/cms/update/leadership", payload),
  // Leadership-Accountablity-content
  addCmsLeadershipAccountablityContent: (payload) => method.post("admin/cms/insert/commitment", payload),
  // Leadership-Board-members
  addCmsBoardmemberImage: (payload) => method.post("admin/cms/add/leadership/board_member", payload, true),
  getCmsBoardmemberdata: () => method.get("admin/cms/get/leadership/board_member"),
  deleteCmsBoardmemberImage: (payload) => method.post("admin/cms/delete/leadership/board_member", payload),
  updateCmsBoardmemberImage: (payload) => method.post("admin/cms/update/leadership/board_member", payload, true),
  // Leadership-missions
  addCmsBoardMissionImage: (payload) => method.post("admin/cms/add/leadership/steering", payload, true),
  getCmsBoardMissiondata: () => method.get("admin/cms/get/leadership/steering"),
  deleteCmsBoardMissionImage: (payload) => method.post("admin/cms/delete/leadership/steering", payload),
  updateCmsBoardMissionImage: (payload) => method.post("admin/cms/update/leadership/steering", payload, true),
  getCmsLeadershipContent: () => method.get("admin/cms/get_page/leadership"),
  //Get Involve
  // Sponser-a-child
  addCmsInvolveWhysponser: (payload) => method.post("admin/cms/insert/why_sponser_a_child", payload, true),
  addCmsInvolveWhatcan: (payload) => method.post("admin/cms/insert/what_can_do", payload, true),
  addCmsInvolveSupportmake: (payload) => method.post("admin/cms/support_makes/insert", payload, true),
  getCmsInvolveSponserdata: () => method.get("admin/cms/get_page/sponser_a_child"),
  //sponser-how-we-work
  addCmsSponserHowweworkImage: (payload) => method.post("admin/cms/add/sponser_a_child/how_we_work", payload, true),
  getCmsSponserHowweworkdata: () => method.get("admin/cms/get/sponser_a_child/how_we_work"),
  updateCmsSponserHowweworkContent: (payload) => method.post("/admin/cms/update/sponser_a_child", payload),
  deleteCmsSponserHowweworkImage: (payload) => method.post("admin/cms/delete/sponser_a_child/how_we_work", payload),
  updateCmsSponserHowweworkImage: (payload) => method.post("admin/cms/update/sponser_a_child/how_we_work", payload, true)
}
export default apiFunctions;