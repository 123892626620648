import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar"
import { Card, CardContent, IconButton, Accordion, AccordionDetails, Typography, AccordionSummary } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DOMPurify from 'dompurify';
import "../../../../src/custom.css";
import "../../../../src/assets/css/cms.css";
import Logout from "../../Logout";
// import Data from "./config.json";
import apiFunctions from "../../../apiKit/api";
import Loader from "../../Loader";
import ChildSponserSection from "./childSponser";
import JourneySection from "./journeySection";
import FocusAreaSection from "./focusAreas";
import ImpactSection from "./impactSection";
import TwoBannerSection from "./twoBanners";
import CSRSection from "./csrSection";
import InTheNewsSection from "./news";
import AwardSection from "./award";
import TestomonialSection from "./testimonial";
import AccreditationSection from "./accrediationSection";
import CampaignSection from "./campaignSection";
import BannerSection from "./bannerSection";
import ReduxComponent from "../../reduxData";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import Dropdown from "../../Dropdown";
const Home = () => {
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    //faq section
    const [faqData, setfaqData] = useState([]);
    const dispatch = useDispatch();
    const { faqdata } = useSelector((state) => state.api);
    // variables
    const [Title, setTitle] = useState("");
    const [subTitle, setSubtitle] = useState("");
    const setData = async (data) => {
        const contentData = data?.homePageData[0]?.content;
        if (contentData) {
            const parsedContent = JSON.parse(contentData);
            setTitle(parsedContent.title);
            setSubtitle(parsedContent.subtitle); // Set the subtitle
        } else {
            console.error("Content data is undefined or null");
        }
        setfaqData(data?.faqs);
    }

    useEffect(() => {
        setData(faqdata);
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, [faqdata])
    const handleOpen = () => {
        setIsopen(!isOpen);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "faq",
            "content": {
                "title": Title,
                "subtitle": subTitle
            }
        }
        apiFunctions.updateCmsHomeFAQcontentapi(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });

    }
    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row mb-2">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12" : "col-12 w-100"} dashboard_card main_layout`} >

                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="mt-3 main">
                                <div className=" col-12">
                                    <Card>
                                        <CardContent className="p-lg p-1">
                                            <div className="text-start mt-4 ms-2"><h5 className="fw-bold">Home Page Contents</h5></div>
                                            <div className="container-fluid p-0">
                                                {/* banners section */}
                                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5>Banner-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="mb-2">
                                                            <BannerSection />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>

                                                {/* Child Sponsership section */}
                                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5>Child Sponsership section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="mb-2">
                                                            <ChildSponserSection />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>

                                                {/* WVI Journey section */}
                                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5>WVI Journey section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="mb-2">
                                                            <JourneySection />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* Focus Area section */}
                                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5>Focus Area section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="mb-2">
                                                            <FocusAreaSection />
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* Impact section */}
                                                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Impact-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <ImpactSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* Two Banner section */}
                                                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Two Banner-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <TwoBannerSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* CSR section */}
                                                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">CSR-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <CSRSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* In The News */}
                                                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">In The News-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <InTheNewsSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* Awards */}
                                                <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Awards-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <AwardSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* Testimonial */}
                                                <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Testimonial-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <TestomonialSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* FAQ section */}
                                                {/* <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5>Faq-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="mb-2">
                                                                    <div className="col-lg-6 col-12">
                                                                        <label>Title</label>
                                                                        <input value={Title} onChange={(e) => setTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                                                    </div>
                                                                    <div className="col-lg-6 col-12">
                                                                        <label>Sub Title</label>
                                                                        <input value={subTitle} onChange={(e) => setSubtitle(e.target.value)} className="form-control" type="text" placeholder="Sub Title" />
                                                                    </div>
                                                                </div>
                                                                <div className="text-end">
                                                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="mb-2">
                                                            <div className="col">
                                                                <div>
                                                                    {faqData?.map((faq, index) => (
                                                                        <Accordion key={index}>
                                                                            <AccordionSummary
                                                                                expandIcon={<ExpandMoreIcon />}
                                                                                aria-controls={`panel${index}-content`}
                                                                                id={`panel${index}-header`}
                                                                            >
                                                                                <Typography className="fw-bold">{faq.question}</Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Typography
                                                                                    component="div"
                                                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.answer) }}
                                                                                />
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion> */}
                                                {/* Campaign */}
                                                {/* <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Campaign-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <CampaignSection />
                                                    </AccordionDetails>
                                                </Accordion> */}
                                                {/* Accreditation */}
                                                <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <h5 className="mt-3">Accreditation-section</h5>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <AccreditationSection />
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Home;