export const pageRoutes = {
  login: "/",
  dashboard: "/dashboard",
  banners: "/banners",
  article: "/article",
  addArticle: "/add-article",
  passwordChange: "/change-password",
  cmsHome: "/cms/home",
  FAQ: "/faq",
  header: "/header",
  footer: "/footer",
  myworld: "/myworld-banner",
  sponserChild: "/cms/get-involve/sponser-child",
  campaign: "/cms/campaign-banner",
  howtowork: "/cms/about/how-to-work",
  storyofchange: "/cms/about/story-of-change",
  partnerspeak: "/cms/about/partner-speak",
  leadership: "/cms/about/leadership-team",


}