import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {thunk} from 'redux-thunk';
import ApiSlice from './slice/slice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  api: ApiSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // This disables checks for non-serializable values, useful when dealing with certain async/persistent data
    }).concat(thunk), // Optionally include thunk if you're not already using it by default
});

export const persistor = persistStore(store);
