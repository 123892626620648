import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiFunctions from "../../../apiKit/api";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
const TwoBannerSection = () => {
    const [Logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const dispatch = useDispatch();
    const { twobannerdata } = useSelector((state) => state.api);

    // variables
    const [bannerTitle, setBannerTitle] = useState("");
    const [bannersubTitle, setBannersubTitle] = useState("");
    const [sponsTitle, setSponsTitle] = useState("");
    const [sponsDescription, setSponsDescription] = useState("");
    const [sponsDescription1, setSponsDescription1] = useState("");
    const [sponsBtn, setSponsBtn] = useState("");
    const [sponsLink, setSponsLink] = useState("");
    const [donateTitle, setDonateTitle] = useState("");
    const [donateDescription, setDonateDescription] = useState("");
    const [donateDescription1, setDonateDescription1] = useState("");
    const [donateBtn, setDonateBtn] = useState("");
    const [donateLink, setDonateLink] = useState("");

    const setData = async (data, image) => {
        setBannerTitle(data?.title);
        setSponsTitle(data?.sponstitle);
        setSponsDescription(data?.sponsdescription);
        setSponsDescription1(data?.sponsdescription1);
        setSponsBtn(data?.sponsbtn);
        setSponsLink(data?.sponslink);
        setDonateTitle(data?.donatetitle);
        setDonateDescription(data?.donatedescription);
        setDonateDescription1(data?.donatedescription1);
        setDonateBtn(data?.donatebtn);
        setDonateLink(data?.donatelink);
        setLogo(appConstants.imageUrl + image);

    }

    useEffect(() => {
        setData(JSON.parse(twobannerdata[0]?.content), twobannerdata[0]?.images);
    }, [twobannerdata])

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        // formData.append("fieldName", "two-banner-section");
        var json = {
            "title": bannerTitle,
            "subtitle": bannersubTitle,
            "sponstitle": sponsTitle,
            "sponsdescription": sponsDescription,
            "sponsdescription1": sponsDescription1,
            "sponsbtn": sponsBtn,
            "sponslink": sponsLink,
            "donatetitle": donateTitle,
            "donatedescription": donateDescription,
            "donatedescription1": donateDescription1,
            "donatebtn": donateBtn,
            "donatelink": donateLink
        }
        formData.append("contents", JSON.stringify(json));
        if (LogoFile instanceof File) {
            formData.append("image", LogoFile);
        }
        apiFunctions.updateCmsHomeTwobannerapi(formData).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }
    return (
        <>
            <div className="row">
                <div className="col-12 w-100">
                    <Card className="p-lg-2 p-0">
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <div>
                                    {Logo ?
                                        <div style={{ width: "150px" }}>
                                            <img className="input-img" src={Logo} alt="demo" />
                                        </div> : ''}
                                    <div className="d-flex mt-2 multi-img">
                                        <input
                                            className="form-control foot-input"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const objectUrl = URL.createObjectURL(file);
                                                    setLogoFile(file);
                                                    setLogo(objectUrl);
                                                }
                                            }}
                                            type="file"
                                            accept="image/png, image/jpeg"
                                        />
                                        <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>Title</label>
                                        <input className="form-control" value={bannerTitle} onChange={(e) => setBannerTitle(e.target.value)} type="text" placeholder="Join us in creating" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>SubTitle</label>
                                        <input className="form-control" value={bannersubTitle} onChange={(e) => setBannersubTitle(e.target.value)} type="text" placeholder="a better world for children" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-12">
                                        <label><b>Sponser</b></label>
                                        <input value={sponsTitle} onChange={(e) => setSponsTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                    </div>
                                    <div className="col-12">
                                        <label>description-1</label>
                                        <textarea value={sponsDescription} onChange={(e) => setSponsDescription(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                    </div>
                                    <div className="col-12">
                                        <label>description-2</label>
                                        <textarea value={sponsDescription1} onChange={(e) => setSponsDescription1(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-lg-6 col-12">
                                        <input value={sponsBtn} onChange={(e) => setSponsBtn(e.target.value)} className="form-control" type="text" placeholder="Sponser" />
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <input value={sponsLink} onChange={(e) => setSponsLink(e.target.value)} className="form-control" type="text" placeholder="SponserLink" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-4 col-12">
                                        <label><b>Donate</b></label>
                                        <input value={donateTitle} onChange={(e) => setDonateTitle(e.target.value)} className="form-control" type="text" placeholder="Title" />
                                    </div>
                                    <div className="col-12">
                                        <label>description-1</label>
                                        <textarea value={donateDescription} onChange={(e) => setDonateDescription(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                    </div>
                                    <div className="col-12">
                                        <label>description-2</label>
                                        <textarea value={donateDescription1} onChange={(e) => setDonateDescription1(e.target.value)} rows={3} className="form-control" type="text" placeholder="description" />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-6 col-12">
                                        <input value={donateBtn} onChange={(e) => setDonateBtn(e.target.value)} className="form-control" type="text" placeholder="Donate" />
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <input value={donateLink} onChange={(e) => setDonateLink(e.target.value)} className="form-control" type="text" placeholder="DonateLink" />
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </>
    )
}
export default TwoBannerSection