import React, { useEffect, useState } from "react";
import SideBar from "../../Sidebar";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import "../../../../src/custom.css";
import Logout from "../../Logout";
import Loader from "../../Loader";
import Dropdown from "../../Dropdown";
import apiFunctions from "../../../apiKit/api";
import ReduxComponent from "../../../components/reduxData";
import { Card, CardContent, IconButton } from "@mui/material";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAPIs } from "../../../redux/slice/slice";
import { appConstants } from "../../../apiKit/appConstant";
import LeadershipMemberTable from "./leadershipmember";
import LeadershipMissionTable from "./leadershipmissiontable";

const Leadership = () => {
    const dispatch = useDispatch();
    const [isOpen, setIsopen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);


    //Guide 
    const [guideTitle, setGuideTitle] = useState("");
    const [guidesubTitle, setsubGuideTitle] = useState("");
    const [logo, setLogo] = useState(null);
    const [LogoFile, setLogoFile] = useState(null);
    const [guidedescription1, setGuideDescription1] = useState("");
    const [guidedescription2, setGuideDescription2] = useState("");
    //Board content
    const [boardTitle, setBoardTitle] = useState("");
    const [boardDescription1, setBoardDescription1] = useState("");
    const [boardDescription2, setBoardDescription2] = useState("");
    //Steer content
    const [steerTitle, setSteerTitle] = useState("");
    const [steerDescription, setSteerDescription] = useState("");
    //Accountablity content
    const [accTitle, setAccTitle] = useState("");
    const [accLogo, setAccLogo] = useState(null);
    const [accLogoFile, setAccLogoFile] = useState(null);
    const [accDescription1, setAccDescription1] = useState("");
    const [accDescription2, setAccDescription2] = useState("");

    const { leadershipdata } = useSelector((state) => state.api);

    const handleOpen = () => {
        setIsopen(!isOpen);
    }
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
        console.log(leadershipdata, "leadershipdata data");
        setData(leadershipdata);
    }, [leadershipdata])


    const setData = (data) => {
        const guide = JSON.parse(data[0]?.content);
        const member = JSON.parse(data[1]?.content);
        const steer = JSON.parse(data[2]?.content);
        const commitment = JSON.parse(data[3]?.content);




        setGuideTitle(guide?.title);
        setsubGuideTitle(guide?.orangetxt);
        setGuideDescription1(guide?.description1);
        setGuideDescription2(guide?.description2);
        setLogo(appConstants.imageUrl + data[0]?.images);
        setBoardTitle(member?.boardtitle);
        setBoardDescription1(member?.boarddescription1);
        setBoardDescription2(member?.boarddescription2);
        setSteerTitle(steer?.steertitle);
        setSteerDescription(steer?.steerdescription);
        setAccTitle(commitment?.acctitle);
        setAccDescription1(commitment?.accdescription1);
        setAccDescription2(commitment?.accdescription2);
        setAccLogo(appConstants.imageUrl + data[3]?.images);
    }

    const handleGuideSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "title": guideTitle,
            "orangetxt": guidesubTitle,
            "description1": guidedescription1,
            "description2": guidedescription2,
        }
        if (LogoFile) {
            form.append("image", LogoFile)
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsLeadershipguideContent(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    const handleBoardSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "board_member",
            "content": {
                "boardtitle": boardTitle,
                "boarddescription1": boardDescription1,
                "boarddescription2": boardDescription2
            }
        }
        apiFunctions.addCmsLeadershipBoardContent(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    const handleSteerSubmit = (e) => {
        e.preventDefault();
        var json = {
            "fieldName": "steering",
            "content": {
                "steertitle": steerTitle,
                "steerdescription": steerDescription,
            }
        }
        apiFunctions.addCmsLeadershipSteerContent(json).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    const handleCommitSubmit = (e) => {
        e.preventDefault();
        const form = new FormData();
        var json = {
            "acctitle": accTitle,
            "accdescription1": accDescription1,
            "accdescription2": accDescription2,
        }
        if (accLogoFile) {
            form.append("image", accLogoFile)
        }
        form.append("contents", JSON.stringify(json))
        apiFunctions.addCmsLeadershipAccountablityContent(form).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    text: res.message,
                    icon: 'success'
                }).then((result) => {
                    dispatch(fetchAPIs());
                    if (result.isConfirmed) {
                        // Additional
                    }
                });
            } else {
                Swal.fire({
                    text: res.message,
                    icon: 'error'
                });
            }
        }).catch((err) => {
            Swal.fire({
                text: 'An error occurred. Please try again later.',
                icon: 'error'
            });
        });
    }

    return (
        <>
            <div className="container-fluid p-0 " style={{ overflow: 'hidden' }}>
                <div className="row">
                    <div className={`${isOpen ? "col-lg-2  mob-nav p-0" : "d-none"} sidebar_layout`}>
                        <SideBar />
                    </div>
                    <div className={`${isOpen ? "col-lg-10 col-12  " : "col-12 w-100"} MyWorldBanner_card main_layout`} >
                        <div className="d-flex w-100 justify mob-sticky">
                            <IconButton className="web-btn" onClick={handleOpen} >
                                <MenuIcon />
                            </IconButton>
                            <IconButton className="mob-btn" data-bs-toggle="offcanvas" data-bs-target="#mob-canvas" aria-controls="mob-canvas">
                                <MenuIcon />
                            </IconButton>
                            <div className="logout_dropdown">
                                <Dropdown />
                                <span>Admin</span>
                                <Logout />
                            </div>
                        </div>
                        {/* Page Contents */}
                        {isLoading ?
                            <Loader /> :
                            <div className="row mt-3 main">
                                <Card className="p-2">
                                    <CardContent>
                                        {/* Leadership section */}
                                        <div className="wework-section">
                                            <h5><b>Leadership contents</b></h5>
                                            <div>
                                                <form onSubmit={handleGuideSubmit}>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Our Leadership</label>
                                                            <input value={guideTitle} onChange={(e) => setGuideTitle(e.target.value)} type="text" className="form-control" placeholder="Our Leadership" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Logo</label>
                                                            <div>
                                                                {logo ?
                                                                    <div style={{ width: "300px" }}>
                                                                        <img className="input-img" src={logo} alt="logo" />
                                                                    </div> : ''}
                                                                <div className="mt-2 d-flex">
                                                                    <input
                                                                        className="form-control foot-input"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file) {
                                                                                const objectUrl = URL.createObjectURL(file);
                                                                                setLogoFile(file);
                                                                                setLogo(objectUrl);
                                                                            }
                                                                        }}
                                                                        type="file"
                                                                        accept="image/png, image/jpeg,image/webp"
                                                                    />
                                                                    <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Guiding Our Vision for Change</label>
                                                            <input value={guidesubTitle} onChange={(e) => setsubGuideTitle(e.target.value)} type="text" className="form-control" placeholder="Guiding Our Vision for Change" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-1</label>
                                                            <textarea rows={3} value={guidedescription1} onChange={(e) => setGuideDescription1(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-2</label>
                                                            <textarea rows={3} value={guidedescription2} onChange={(e) => setGuideDescription2(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 mb-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>

                                                <form onSubmit={handleBoardSubmit}>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label className="fw-bold">Board Members</label>
                                                            <input value={boardTitle} onChange={(e) => setBoardTitle(e.target.value)} type="text" className="form-control" placeholder="Board Members" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-1</label>
                                                            <textarea rows={3} value={boardDescription1} onChange={(e) => setBoardDescription1(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-2</label>
                                                            <textarea rows={3} value={boardDescription2} onChange={(e) => setBoardDescription2(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>
                                                <div>
                                                    <LeadershipMemberTable />
                                                </div>

                                                <form onSubmit={handleSteerSubmit}>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label className="fw-bold">Steering Our Mission</label>
                                                            <input value={steerTitle} onChange={(e) => setSteerTitle(e.target.value)} type="text" className="form-control" placeholder="Steering Our Mission" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-1</label>
                                                            <textarea rows={3} value={steerDescription} onChange={(e) => setSteerDescription(e.target.value)} type="text" className="form-control" placeholder="Description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>

                                                <div>
                                                    <LeadershipMissionTable />
                                                </div>

                                                <form onSubmit={handleCommitSubmit} >
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label className="fw-bold">Accountablity and Transparency</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Image</label>
                                                            <div>
                                                                {accLogo ?
                                                                    <div style={{ width: "300px" }}>
                                                                        <img className="input-img" src={accLogo} alt="logo" />
                                                                    </div> : ''}
                                                                <div className="mt-2 d-flex">
                                                                    <input
                                                                        className="form-control foot-input"
                                                                        onChange={(e) => {
                                                                            const file = e.target.files[0];
                                                                            if (file) {
                                                                                const objectUrl = URL.createObjectURL(file);
                                                                                setAccLogoFile(file);
                                                                                setAccLogo(objectUrl);
                                                                            }
                                                                        }}
                                                                        type="file"
                                                                        accept="image/png, image/jpeg,image/webp"
                                                                    />
                                                                    <span className="mt-2 ms-2" style={{ fontWeight: "lighter", fontSize: "14px", color: "grey" }}>(520×250)</span>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Accountablity and Transparency</label>
                                                            <input value={accTitle} onChange={(e) => setAccTitle(e.target.value)} type="text" className="form-control" placeholder="Accountablity and Transparency" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-1</label>
                                                            <textarea rows={3} value={accDescription1} onChange={(e) => setAccDescription1(e.target.value)} type="text" className="form-control" placeholder="description" />
                                                        </div>
                                                        <div className="col-lg-6 col-12">
                                                            <label>Description-2</label>
                                                            <textarea rows={3} value={accDescription2} onChange={(e) => setAccDescription2(e.target.value)} type="text" className="form-control" placeholder="description" />
                                                        </div>
                                                    </div>
                                                    <div className="text-start">
                                                        <button className="mt-3 btn" type="submit" variant="contained">Save</button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>


                                    </CardContent>
                                </Card>


                            </div>
                        }

                    </div>
                </div>
            </div >
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="mob-canvas" aria-labelledby="mob-canvaslabel">
                <div className="offcanvas-header" style={{ background: "transparent" }}>
                    <IconButton data-bs-dismiss="offcanvas" aria-label="Close">
                        <CloseIcon style={{ height: '40px', width: '40px', color: 'black' }} />
                    </IconButton>
                </div>
                <div className="offcanvas-body p-0">
                    <SideBar />
                </div>
            </div>
            <ReduxComponent />
        </>
    )
}
export default Leadership;